import { Tooltip } from "@reach/tooltip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPencilAlt } from "react-icons/fa";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import useMarginCalculationMethods from "../../../../hooks/useMarginCalculationMethods";
import ErrorIcon from "../../../ErrorIcon";
import classNames from "classnames";

export const EditableCellNetPrice = ({ row: { original }, value: initialValue, handleUpdateItem, setIsTableValid, cart }: any) => {
    const { semiramisRound } = useMarginCalculationMethods();
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();

    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [cellError, setCellError] = useState<string | null>(null);

    const onBlur = async (ev: any) => {
        try {
            await handleUpdateItem(original.id, original.qty, original.discount_1, original.discount_2, ev.target.value);
            setIsEditing(false);
            setIsTableValid((prevState: any) => prevState.filter((cellId: any) => {
                return cellId !== ev.target.id;
            }));
        } catch (err: any) {
            if (err?.response?.status === 422) {
                setCellError(t("Il prezzo manuale deve essere un numero, maggiore di 0 e massimo 5 decimali"));
                setIsTableValid((prevState: any) => prevState.includes(ev.target.id) ? prevState : [...prevState, ev.target.id]);
            }
        }
    };

    let price = original.net_price ? parseFloat(original.net_price.toString()) : 0;
    const manualNetPrice = original.manual_net_price;

    if (isNaN(price)) {
        price = 0;
    }

    price = semiramisRound(price, 5);

    const numberFormat = new Intl.NumberFormat(cart?.data?.customer?.language_id, { minimumFractionDigits: 5 });
    let result;
    if (manualNetPrice) {
        result = numberFormat.format(manualNetPrice);
    } else {
        result = numberFormat.format(price);
    }

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div className="flex justify-end">
            {
                isEditing ?
                    <div className="flex items-center gap-x-1">
                        <input
                            type="number"
                            className={`${!cellError ? "w-20 p-1" : "w-20 p-1 border-rose-500 outline-none rounded"}`}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={(e) => onBlur(e)}
                        />
                        {cellError && <ErrorIcon>{cellError}</ErrorIcon>}
                    </div>
                    :
                    <div className={classNames("flex items-center gap-x-1", {
                        "cursor-pointer": !roles(user?.constants?.external_roles),
                    })} onClick={() => !roles(user?.constants?.external_roles) ? setIsEditing(true) : null}>
                        <span>{result}</span>
                        {
                            !roles(user?.constants?.external_roles) &&
                            <Tooltip
                                label={t("Inserisci prezzo")}
                                style={{
                                    backgroundColor: "#F1F5F6",
                                    borderRadius: "3px",
                                    fontWeight: "300",
                                    marginRight: "20px",
                                    zIndex: 50
                                }}
                            >
                                <span>
                                    <FaPencilAlt />
                                </span>
                            </Tooltip>
                        }

                        {/* hide underMargin tmp */}
                        {/* {
                            !roles(user?.constants?.external_roles) && original?.isUnderMargin &&
                            <ErrorIcon>{t("Margine insufficiente")}</ErrorIcon>
                        } */}
                    </div>
            }
        </div>
    );
};
