import { useTranslation } from "react-i18next";
import { InputFileDragDrop } from "../../../../commom/InputFileDragDrop";
import { useFormContext } from "react-hook-form";
import { Section } from "../../../../Section";
import { useState } from "react";
import { useAuthContext } from "../../../../../hooks/use-context/useAuthContext";

type Props = {
    flow?: string
};

export const ComplaintAttachFiles = ({ flow = "product" }: Props) => {
    const { register, formState: { errors } } = useFormContext();
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();

    const [files, setFiles] = useState<any[]>([]);

    return (
        <Section title={"Allega documenti o fotografie"}>
            <fieldset>
                <legend className="sr-only">{t("Allega documenti")}</legend>
                <div className='space-y-4'>
                    {/* AMOUNT DAMAGE (ONLY PRODUCT FLOW) */}
                    {
                        !roles(user?.constants?.external_roles) && flow === "product" &&
                        <div className="space-y-2">
                            <p className="text-sm">{t("Ritieni che il danno causato al cliente possa essere superiore a 5000€")}</p>
                            <div className="flex gap-x-4">
                                <div className="flex items-center gap-x-2">
                                    <input
                                        {...register("amount_damage", { required: `${t("- questo campo è obbligatorio")}` })}
                                        className="cursor-pointer"
                                        name="amount_damage"
                                        value={"si"}
                                        id="ad-si"
                                        type="radio"
                                    />
                                    <label className="p-0 cursor-pointer font-light" htmlFor="ad-si">{t("Sì")}</label>
                                </div>
                                <div className="flex items-center gap-x-2">
                                    <input
                                        {...register("amount_damage", { required: `${t("- questo campo è obbligatorio")}` })}
                                        className="cursor-pointer"
                                        name="amount_damage"
                                        value={"no"}
                                        id="ad-no"
                                        type="radio"
                                    />
                                    <label className="p-0 cursor-pointer font-light" htmlFor="ad-no">{t("No")}</label>
                                </div>
                            </div>
                            {errors?.amount_damage && <p className="text-sm text-rose-500 mt-1">{errors?.amount_damage.message}</p>}
                        </div>
                    }

                    {/* INPUT FILE */}
                    <div>
                        <InputFileDragDrop inputName="complaint_files" container={files} setContainer={setFiles} />
                    </div>
                </div>
            </fieldset>
        </Section>
    );
};
