import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { Item } from "../../../interfaces/Item";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import CartTable from "./cart-table/CartTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useCart from "../../../api/carts/useCart";
import SaveCartModal from "./SaveCartModal";
import { useCartItemsMutations } from "../../../api/carts/useCartItemsMutations";
import { AddProductForm } from "../../commom/AddProductForm";
import { CustomerForm } from "../../commom/CustomerForm";
import client from "../../../api/client";
import { useShippingContext } from "../../../hooks/use-context/useShippingContext";
import useCartsMutations from "../../../api/carts/useCartsMutations";
import { Modal } from "../../commom/Modal";
import { toast, ToastContainer, Slide } from "react-toastify";
import useGetCustomers from "../../../api/customers/useGetCustomers";
import { Dialog } from "@reach/dialog";
import { Title } from "../../Title";
import debounce from "lodash.debounce";
import { Message } from "../../Message";
import useCheckCartPricesMutations from "../../../api/carts/useCheckCartPricesMutations";
import { Loader } from "../../Loader";
import useCheckCartPrices from "../../../api/carts/useCheckCartPrices";
import { downloadResource } from "../../../constants/DownloadResource";
import { Section } from "../../Section";
import { Slider } from "../../commom/Slider";
import { ItemCard } from "../../commom/ItemCard";
import useSuggestedProducts from "../../../api/suggested-products/useSuggestedProducts";
import { ClipLoader } from "react-spinners";
import useCartOfferColumns from "../../../hooks/useCartOfferColumns";
import Switch from "../../commom/Switch";

export const Cart = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const locState = location.state as any;

    // context
    const { can, user, roles, showMarginColumns, setShowMarginColumns } = useAuthContext();
    const { resetShippingContext, client_reference } = useShippingContext();

    // state
    const [openItem, setOpenItem] = useState<boolean>(false);
    const [openSaveCart, setOpenSaveCart] = useState<boolean>(false);
    const [openEmptyList, setOpenEmptyList] = useState<boolean>(false);
    const [openRemovedItems, setOpenRemovedItems] = useState<boolean>(false);
    const [isTableValid, setIsTableValid] = useState<any>([]);
    const [disableAddButton, setDisableAddButton] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [qty, setQty] = useState(0);
    const [discount1, setDiscount1] = useState("0");
    const [discount2, setDiscount2] = useState("0");
    const [openUpdatedProductsMessage, setOpenUpdatedProductsMessage] = useState<boolean>(false);
    const [openPriceChangesMessage, setOpenPriceChangesMessage] = useState<boolean>(false);
    const [OpenConfirmUpdatePrices, setOpenConfirmUpdatePrices] = useState<boolean>(false);
    const [isCartValid, setIsCartValid] = useState<boolean>(false);

    // refs
    const customerSelect: any = useRef();
    const addProductFormRef: any = useRef();
    const tableRef: any = useRef();

    // react query
    const { loadCustomersOptions } = useGetCustomers();
    const { saveCart, updateCart } = useCartsMutations();
    const { addProduct, deleteAllProducts } = useCartItemsMutations();
    const { data: cart, selectedCustomer, setSelectedCustomer, isLoading, isFetching } = useCart(id);
    const { priceChanges, isLoading: isPriceChangesLoading, isFetching: isPriceChangesFetching } = useCheckCartPrices(id, !!cart?.data?.is_dirty);
    const { updatedPrices, updatePrices, updatePricesLoading } = useCheckCartPricesMutations();
    const { data: suggestedProducts, isLoading: isSuggestedLoading, isFetching: isSuggestedFetching } = useSuggestedProducts(cart?.data?.id);

    // hooks
    const columns = useCartOfferColumns(cart, showMarginColumns);

    // handle customer SELECT change
    const handleCustomerChange = async (inputValue: any) => {
        // if customer changes set cart items and shipping info to original states
        if (inputValue) {
            resetShippingContext();

            setSelectedCustomer(inputValue);

            const params = {
                cart_id: cart?.data?.id,
                body: {
                    customer_code: inputValue?.code ?? null,
                }
            };

            await updateCart.mutateAsync(params);

            setQty(0);
            setDiscount1("0");
            setDiscount2("0");
        } else {
            setSelectedCustomer(null);
        }
    };

    // handle item search INPUT change
    const handleItemSearchInputChange = (value: any) => {
        if (value === "") {
            setOpenItem(false);
        } else {
            setOpenItem(true);
        }
    };

    // handle item search SELECT change
    const handleItemSearchChange = async (inputValue: any) => {
        if (inputValue) {
            const params = {
                "filter[customer_code]": cart?.data?.customer.code
            };

            // only get discount if not client
            if (!roles(user?.constants?.external_roles)) {

                setDisableAddButton(true);

                const res = await client.get(`products/${inputValue.value.code}/pricing`, {
                    params: params
                });

                setDiscount1(parseFloat(res.data.data.discount_1).toFixed(2));
                setDiscount2(parseFloat(res.data.data.discount_2).toFixed(2));

                setDisableAddButton(false);
            }
            // set qty for both client and sales force
            setQty(parseInt(inputValue.value.minsellable));
        }

        setSelectedItem(inputValue?.value);
    };

    // handle click to empty table
    const handleEmptyClick = async () => {
        await deleteAllProducts.mutateAsync({ cart_id: cart?.data?.id });
        setOpenEmptyList(false);
    };

    // handle next step click
    const handleNextStepClick = async () => {
        const params = {
            cart_id: cart?.data?.id,
            body: {
                customer_code: cart?.data?.customer.code,
                client_reference
            }
        };

        await updateCart.mutateAsync(params);

        navigate("shipping");
    };

    // handle save button click
    const handleSaveClick = async () => {
        setOpenSaveCart(true);
    };

    // close removed items modal and empty location state
    const closeRemovedItemsModal = () => {
        setOpenRemovedItems(false);
        window.history.replaceState({}, document.title);
    };

    // handle form submit
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisableAddButton(true);

        // send discoutn 1 & 2 only if user is not a client
        const body = {
            product_code: selectedItem?.code,
            quantity: Number(qty),
            ...(!roles(user?.constants?.external_roles) ? { discount_1: Number(discount1).toFixed(2) } : {}),
            ...(!roles(user?.constants?.external_roles) ? { discount_2: Number(discount2).toFixed(2) } : {})
        };

        try {
            await toast.promise(addProduct.mutateAsync({ cart_id: cart?.data?.id, body: body }), {
                pending: t("Carico..."),
                success: t("Modifica effettuata con successo"),
                error: {
                    render({ data }: any) {
                        if (data?.response?.status === 403) {
                            return t(data?.response?.data?.message);
                        } else {
                            return t("Error, something went wrong");
                        }
                    }
                }
            });

            setSelectedItem(null);
            setDisableAddButton(false);
            setQty(0);
            setDiscount1("0");
            setDiscount2("0");

            addProductFormRef.current.searchSelectClearValue();
            addProductFormRef.current.searchSelectfocus();
        } catch (err: any) {
            setDisableAddButton(false);
        }
    };

    const handleExportXlsx = async () => {

        const res = await client.get(`carts/${cart?.data?.id}/xlsx-download`, {
            headers: { responseType: "blob" }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }
    };

    // GET ITEMS
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
            "filter[customer_code]": cart?.data?.customer?.code
        };

        const res = await client.get("products", { params: params });

        // console.log(res);

        return res.data.data.map((item: any) => {
            let pieces = null;
            if (parseInt(item.piecesperpackage, 10) > 1) {
                pieces = <>({parseInt(item.piecesperpackage, 10)} {item.piecesperpackageuom})</>;
            }
            return {
                label: <span>{item.code} {item?.replacing_code && (item?.replacing_code === value) ? <span className='font-bold p-1 rounded'>{t("Vecchio codice")}: {item?.replacing_code}</span> : null} - {item.description} {pieces}</span>,
                value: item
            };
        });
    }, [cart?.data?.customer?.code]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    const manuallyUpdateCart = async () => {
        await updatePrices({ cart_id: cart?.data?.id });
        setOpenUpdatedProductsMessage(true);
        setOpenConfirmUpdatePrices(false);
    };

    // set initial focus on item or customer select based on user roles
    useEffect(() => {
        if (roles(user?.constants?.external_roles) || cart?.data.customer) {
            addProductFormRef?.current?.searchSelectfocus();
        } else {
            customerSelect?.current?.focus();
        }
    }, [roles, cart?.data.customer]);

    // set focus on qty when an item is selected
    useEffect(() => {
        addProductFormRef?.current?.qtyInputFocus();
        addProductFormRef?.current?.qtyInputSelect();
    }, [selectedItem]);

    // open removed items modal if it's present on location state
    useEffect(() => {
        if (locState?.removedItems?.length > 0) setOpenRemovedItems(true);
    }, []);

    useEffect(() => {
        if (priceChanges?.data?.length > 0) setOpenPriceChangesMessage(true);
    }, [priceChanges]);

    useEffect(() => {
        setIsCartValid(isTableValid.length === 0 && !cart?.data?.items.find((e: any) => !!e?.is_minsell_invalid || !!e?.is_invalid));
    }, [cart, isTableValid]);

    return (
        (
            cart
                ? <>
                    {/* modal that shows removed products when an offer is duplicated */}
                    {
                        openRemovedItems &&
                        <Dialog isOpen={openRemovedItems} onDismiss={closeRemovedItemsModal} className="font-light text-sm space-y-2">
                            <Title variant={Title.variant.secondary}>{t("Articoli rimossi")}</Title>
                            <p>{t("I seguenti prodotti non vendibili per la consociata selezionata sono stati rimossi:")}</p>
                            <ul>
                                {
                                    locState?.removedItems?.map((removedItem: any) => (
                                        <li key={removedItem}>- {removedItem}</li>
                                    ))
                                }
                            </ul>
                            <div className='text-right'>
                                <Button onClick={closeRemovedItemsModal}>Ok</Button>
                            </div>
                        </Dialog>
                    }

                    {/* CONFIRM PRICE CHANGES MODAL */}
                    {
                        OpenConfirmUpdatePrices &&
                        <Modal title={t("Aggiorna sconti")} open={OpenConfirmUpdatePrices} onClose={setOpenConfirmUpdatePrices}>
                            <div className="space-y-4">
                                <p>{t("ATTENZIONE: Gli sconti e i prezzi netti inseriti manualmente saranno aggiornati in base alle più recenti scontistiche personalizzate del cliente.")}</p>

                                <div className="text-right space-x-2 mt-4">
                                    <Button variant={Button.variant.text} onClick={() => setOpenConfirmUpdatePrices(false)}>{t("Annulla")}</Button>
                                    <Button variant={Button.variant.primary} onClick={manuallyUpdateCart}>{t("Procedi")}</Button>
                                </div>
                            </div>
                        </Modal>
                    }

                    {/* react toastfy */}
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                    />

                    {/* save cart modal */}
                    {openSaveCart && <SaveCartModal open={openSaveCart} onClose={setOpenSaveCart} saveCart={saveCart} updateCart={updateCart} data={cart} />}

                    {/* empty cart list modal */}
                    {
                        openEmptyList &&
                        <Modal title={t("Svuota carrello")} open={openEmptyList} onClose={setOpenEmptyList}>
                            <p>{t("Sicuro di voler svuotare il carrello? Questa operazione non può essere annullata.")}</p>
                            <div className="text-right space-x-2 mt-4">
                                <Button variant={Button.variant.text} onClick={() => setOpenEmptyList(false)}>{t("Annulla")}</Button>
                                <Button variant={Button.variant.primary} onClick={handleEmptyClick}>{t("Procedi")}</Button>
                            </div>
                        </Modal>
                    }

                    {/* loader */}
                    {(updatePricesLoading || isLoading || isFetching || ((isPriceChangesLoading || isPriceChangesFetching) && !!cart?.data?.is_dirty)) && <Loader />}

                    <form onSubmit={handleSubmit}>
                        {/* SELECT CUSTOMER */}
                        <CustomerForm
                            ref={customerSelect}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            data={cart}
                            handleCustomerChange={handleCustomerChange}
                            loadCustomersOptions={loadCustomersOptions}
                        />

                        {/* ADD PRODUCT */}
                        {
                            cart?.data?.customer?.alloworder &&
                            <AddProductForm
                                data={cart}
                                ref={addProductFormRef}
                                setSelectedItem={setSelectedItem}
                                selectedCustomer={selectedCustomer}
                                selectedItem={selectedItem}
                                disableAddButton={disableAddButton}
                                loadItemsOptions={loadItemsOptions}
                                handleItemSearchChange={handleItemSearchChange}
                                handleItemSearchInputChange={handleItemSearchInputChange}
                                openItem={openItem}
                                qty={qty}
                                setQty={setQty}
                                discount1={discount1}
                                setDiscount1={setDiscount1}
                                discount2={discount2}
                                setDiscount2={setDiscount2}
                            />
                        }
                    </form>

                    {/* PRICE CHANGES MESSAGE */}
                    {
                        priceChanges?.data?.length > 0 &&
                        <Message variant={Message.variant.warning} open={openPriceChangesMessage} handleCloseMessage={() => setOpenPriceChangesMessage(false)}>
                            <div className="space-y-4">
                                <div>
                                    <p>{t("ATTENZIONE: I  prezzi dei seguenti articoli sono aggiornati al nostro listino più recente:")}</p>
                                    <p>{t("Verifica l'importo totale prima di procedere allo step successivo.")}</p>
                                </div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th className="pr-4 pb-2 text-left">{t("Codice articolo")}</th>
                                            <th className="pr-4 pb-2 text-left">{t("Descrizione")}</th>
                                            <th className="pr-4 pb-2 first-letter:uppercase">{t("prezzo vecchio")}</th>
                                            <th className="pr-4 pb-2 first-letter:uppercase">{t("prezzo nuovo")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priceChanges?.data?.map((priceChange: any, index: number) => (
                                            <tr key={index}>
                                                <td className="pr-4 pb-2">{priceChange?.code}</td>
                                                <td className="pr-4 pb-2">{priceChange?.description}</td>
                                                <td className="pr-4 pb-2 text-right">{priceChange?.old_price}</td>
                                                <td className="pr-4 pb-2 text-right">{priceChange?.new_price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Message>
                    }

                    {/* DISCOUNT CHANGES MESSAGE */}
                    <Message variant={Message.variant.warning} open={openUpdatedProductsMessage} handleCloseMessage={() => setOpenUpdatedProductsMessage(false)}>
                        {
                            updatedPrices?.data?.data?.length > 0 ?
                                <div className="space-y-4">
                                    <div>
                                        <p>{t("ATTENZIONE: I  prezzi dei seguenti articoli sono aggiornati al nostro listino più recente:")}</p>
                                        <p>{t("Verifica l'importo totale prima di procedere allo step successivo.")}</p>
                                    </div>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="pr-4 pb-2 text-left">{t("Codice articolo")}</th>
                                                <th className="pr-4 pb-2 text-left">{t("Descrizione")}</th>
                                                <th className="pr-4 pb-2 first-letter:uppercase">{t("sconto vecchio %")}</th>
                                                <th className="pr-4 pb-2 first-letter:uppercase">{t("sconto nuovo %")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                updatedPrices?.data?.data?.map((priceChange: any, index: number) => (
                                                    <tr key={index}>
                                                        <td className="pr-4 pb-2">{priceChange?.code ? priceChange?.code : "-"}</td>
                                                        <td className="pr-4 pb-2">{priceChange?.description}</td>
                                                        <td className="pr-4 pb-2 text-right space-y-2">
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                                                                <div>{priceChange?.old_discount_1 ? priceChange?.old_discount_1 + "%" : "-"}</div>
                                                            </div>
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                                                                <div>{priceChange?.old_discount_2 ? priceChange?.old_discount_2 + "%" : "-"}</div>
                                                            </div>
                                                        </td>
                                                        <td className="pr-4 pb-2 text-right space-y-2">
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                                                                <div>{priceChange?.new_discount_1 ? priceChange?.new_discount_1 + "%" : "-"}</div>
                                                            </div>
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                                                                <div>{priceChange?.new_discount_2 ? priceChange?.new_discount_2 + "%" : "-"}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div>
                                    {t("Nessuno sconto e/o prezzo netto manuale da aggiornare.")}
                                </div>
                        }
                    </Message>

                    {/* cart table  */}
                    {
                        cart?.data?.items?.length > 0 &&
                        <>
                            {/* MARGIN CALCULATOR TOGGLE */}
                            {
                                can(["can_see_margin_calculator"]) &&
                                <div className="flex justify-end pb-2">
                                    <Switch checked={showMarginColumns} setChecked={setShowMarginColumns} label={t("Visualizza margin calculator")} />
                                </div>
                            }

                            {/* ACTIONS */}
                            {/* {
                                cart?.data?.items.length >= 10 &&
                                <div className='flex justify-between mb-3'>
                                    <div>
                                        <Button icon={Button.icon.delete} variant={Button.variant.text} onClick={() => setOpenEmptyList(true)}>
                                            {t("Svuota lista prodotti")}
                                        </Button>

                                    </div>
                                    <div className='space-x-4 mb-3'>
                                        {
                                            !roles(user?.constants?.external_roles) &&
                                            <Button
                                                variant={Button.variant.secondary}
                                                onClick={() => setOpenConfirmUpdatePrices(true)}
                                                isDisabled={!isCartValid}
                                            >
                                                {t("Ripristina sconti")}
                                            </Button>
                                        }
                                        {
                                            !roles(user?.constants?.external_roles) &&
                                            <Button
                                                variant={Button.variant.secondary}
                                                onClick={handleExportXlsx}
                                            >
                                                {t("Scarica XLS")}
                                            </Button>
                                        }
                                        <Button
                                            variant={Button.variant.secondary}
                                            onClick={handleSaveClick}
                                            isDisabled={!isCartValid}
                                        >
                                            {t("Salva carrello")}
                                        </Button>
                                        <Button
                                            variant={Button.variant.primary}
                                            onClick={handleNextStepClick}
                                            isDisabled={!isCartValid}
                                        >
                                            {t("Procedi")}
                                        </Button>
                                    </div>
                                </div>
                            } */}

                            {/* CART TABLE */}
                            <div ref={tableRef}>
                                <CartTable
                                    cart={cart}
                                    setIsTableValid={setIsTableValid}
                                    isTableValid={isTableValid}
                                    cartColumns={columns}
                                    showMarginColumns={showMarginColumns}
                                />
                            </div>

                            {/* ACTIONS */}
                            <div className='flex justify-between'>
                                <div>
                                    <Button icon={Button.icon.delete} variant={Button.variant.text} onClick={() => setOpenEmptyList(true)}>
                                        {t("Svuota lista prodotti")}
                                    </Button>
                                </div>
                                <div className='space-x-4'>
                                    {
                                        !roles(user?.constants?.external_roles) &&
                                        <Button
                                            variant={Button.variant.secondary}
                                            onClick={() => setOpenConfirmUpdatePrices(true)}
                                            isDisabled={!isCartValid}
                                        >
                                            {t("Ripristina sconti")}
                                        </Button>
                                    }
                                    {
                                        !roles(user?.constants?.external_roles) &&
                                        // roles(["CS", "CS_Other", "BA"]) &&
                                        <Button
                                            variant={Button.variant.secondary}
                                            onClick={handleExportXlsx}
                                        >
                                            {t("Scarica XLS")}
                                        </Button>
                                    }
                                    <Button
                                        variant={Button.variant.secondary}
                                        onClick={handleSaveClick}
                                        isDisabled={!isCartValid}
                                    >
                                        {t("Salva carrello")}
                                    </Button>
                                    <Button
                                        variant={Button.variant.primary}
                                        onClick={handleNextStepClick}
                                        isDisabled={!isCartValid || !cart.data.customer.alloworder}
                                    >
                                        {t("Procedi")}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }

                    {
                        cart?.data?.items?.length > 0 && Array.isArray(suggestedProducts) && suggestedProducts.length > 0 &&
                        <Section title="Prodotti correlati" className="mb-20 h-[600px]">
                            {cart?.data?.id && !(isSuggestedLoading || isSuggestedFetching) ?
                                <div className="-mx-7">
                                    {!(isSuggestedLoading || isSuggestedFetching) && <Slider>
                                        {suggestedProducts?.map((el, index) => {
                                            return <div key={index} className="min-w-[100%] sm:min-w-[50%] md:min-w-[calc(100%/3)] lg:min-w-[25%] w-[100%] sm:w-[50%] md:w-[calc(100%/3)] lg:w-[25%] flex justify-center">
                                                <div className="max-w-[400px] w-full">
                                                    <ItemCard
                                                        cart_id={cart?.data?.id}
                                                        code={el.code}
                                                        description={el.description}
                                                        min={el.min}
                                                        family={el.family}
                                                        addProduct={addProduct}
                                                        isLoading={isLoading || isFetching}
                                                        anchor={tableRef}
                                                    />
                                                </div>
                                            </div>;
                                        })}
                                    </Slider>}
                                </div> :
                                <div className='flex h-full justify-center items-center'>
                                    <ClipLoader
                                        color={"black"}
                                        loading={true}
                                        size={70}
                                        aria-label="Loading Spinner"
                                    />
                                </div>
                            }
                        </Section>
                    }
                </>
                : null

        )
    );
};
