export const GrossPricelistLanguages = [{
    "value": "it",
    "label": "Italiano"
}, {
    "value": "en",
    "label": "English"
}, {
    "value": "de",
    "label": "Deutsch"
}, {
    "value": "es",
    "label": "Español"
}, {
    "value": "fr",
    "label": "Français"
}, {
    "value": "pt",
    "label": "Português"
}, {
    "value": "hu",
    "label": "Magyar"
}, {
    "value": "pl",
    "label": "Polski"
}, {
    "value": "sl",
    "label": "Slovenščina"
}, {
    "value": "cs",
    "label": "Čeština"
}];
