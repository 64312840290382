import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelectStyles } from "../../../hooks/useSelectStyles";
import { useEffect, useRef, useState } from "react";
import client from "../../../api/client";
import { MdClose } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { Section } from "../../Section";
import { useShippingContext } from "../../../hooks/use-context/useShippingContext";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";

type Props = {
    data: any
};

export const CustomerPaymentConditions = ({ data }: Props) => {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();
    const { CustomStyles } = useSelectStyles();
    const { setPaymentMethod, setPaymentTerms } = useShippingContext();

    // state
    const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
    const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false);

    const methodSelectRef: any = useRef();

    const handleMethodSelectChange = (inputValue: any) => {
        if (inputValue) {
            setPaymentMethod(inputValue?.paymentMethod);
            setPaymentTerms(inputValue?.paymentTerm);
        } else {
            setPaymentMethod(null);
            setPaymentTerms(null);
        }
    };

    const onCloseMethod = () => {
        methodSelectRef.current.clearValue();
        setIsEditingPaymentMethod(false);
    };

    // GET PAYMENT METHODS
    const getPaymentMethods = async () => {
        const res = await client.get(`/customers/${data?.data?.customer?.code}/payment-conditions`);
        const tmp = res.data.data.map((item: any) => {
            return { ...item, label: item?.paymentMethod?.description + " - " + item.paymentTerm.description }
        })
        setPaymentMethodsArr(tmp);
    };

    useEffect(() => {
        if (!roles(user?.constants?.external_roles)) {
            getPaymentMethods();
        }
    }, []);

    // OVERRIDE OPTION COMPONENTO TO DEAL WITH LAG FOR 1000+ RECORDS
    // the CustomOption styles are set within the custom component
    const CustomOption = (props: any) => {
        const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps

        return !props.isDisabled ? (
            <div className="text-primary py-1 hover:bg-gray-200 cursor-pointer" {...newInnerProps}>{props.children}</div>
        ) : null;
    }

    return (
        <Section title={"Condizioni di pagamento"}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* PAYMENT METHOD */}
                <div className="bg-neutral-weaker border p-6">
                    {
                        !isEditingPaymentMethod ?
                            <div>
                                <label className="text-lg font-sans text-fg-lighter font-normal">{t("Metodo di pagamento")}</label>
                                <span className="text-base font-light">{data?.data?.payment_method?.description ?? data?.data?.customer?.finance?.paymentmethod?.description} - {data?.data?.payment_term?.description ?? data?.data?.customer?.finance?.paymentterm?.description}</span>
                                {
                                    paymentMethodsArr && paymentMethodsArr?.length > 1 &&
                                    <div className="pt-6">
                                        <hr />
                                        <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setIsEditingPaymentMethod(true)}>
                                            <FaPen />
                                            <div className="font-normal font-sans text-base">
                                                {t("Modifica")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <label>{t("Seleziona metodo di pagamento")}</label>
                                <div className='flex items-center gap-x-2'>
                                    <div className='flex-1'>
                                        <Select
                                            options={paymentMethodsArr}
                                            ref={methodSelectRef}
                                            components={{ ClearIndicator: () => null, Option: CustomOption }}
                                            onChange={handleMethodSelectChange}
                                            styles={CustomStyles}
                                            placeholder={t("inserisci metodo di pagamento")}
                                            loadingMessage={() => t("Caricamento in corso...")}
                                            noOptionsMessage={() => t("Nessun risultato")}
                                            isClearable
                                            escapeClearsValue
                                        />
                                    </div>
                                    <div className='text-xl'>
                                        <MdClose onClick={onCloseMethod} className='cursor-pointer hover:text-secondary' />
                                    </div>
                                </div>
                            </div>

                    }
                </div>
            </div>
        </Section>
    );
};
