import { useEffect, useState } from "react";
import PreviewShippingItemsTable from "../shippings/PreviewShippingItemsTable";
import PreviewCartItemsTable from "./PreviewCartItemsTable";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import PreviewSemiramisComplaintsTable from "../assistance/semiramis-complaints/PreviewSemiramisComplaintsTable";

// SUB ROW TEMPLATE COMPONENT
const SubRows = ({ data, loading, endpoint }: any) => {
    const { t } = useTranslation();
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const { user, roles } = useAuthContext();

    useEffect(() => {
        const tempHiddenColumn = [];
        if (!data?.isSalesOrder) {
            tempHiddenColumn.push("status");
        }

        // columns "discount_1", "discount_2", "pricevalue" must be hidden only for some endpoints that use PreviewCartItemsTable for now only cart & offers (invoices should have its own permission so who sees an invoice sees the prices too)
        if (["carts", "offers"].includes(endpoint) && parseInt(data.kind_of_pricing) !== 1 && roles(user?.constants?.external_roles)) {
            tempHiddenColumn.push("discount_1", "discount_2", "pricevalue");
        }

        setHiddenColumns(tempHiddenColumn);
    }, [data]);

    if (loading) {
        return (
            <span>
                <div>
                    Loading...
                </div>
            </span>
        );
    }

    if (endpoint === "semiramis-complaints") {
        return <PreviewSemiramisComplaintsTable data={data} />;

    }

    // return diferent preview table for shippings
    if (endpoint === "shipping/shippings") {
        return <PreviewShippingItemsTable data={data} />;
    }

    // return preview table for order/offers/drafts
    return <PreviewCartItemsTable data={data} hiddenColumns={hiddenColumns} />;
};

export default SubRows;
