import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type UpdateAddressParams = {
    cart_id: number,
    delivery_address: any
};

export default function useCartAddressMutations() {
    const queryClient = useQueryClient();

    // SAVE CART
    const updateCartAddress = useMutation(async (params: UpdateAddressParams) => {
        return await client.put(`cart-addresses/${params.cart_id}`, params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["freight-cost"]);
            queryClient.invalidateQueries(["alternativeFreightCost"]);
        }
    });

    return { updateCartAddress };
}
