import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@reach/tooltip";
import { FaPencilAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const EditableCellDescription = ({ value: initialValue, row: { original }, handleUpdateDescription }: any) => {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    // state
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const onChange = (e: any) => {
        if (e.target.value !== "") {
            setValue(e.target.value);
            setIsDirty(true);
        } else {
            setValue(initialValue);
            setIsDirty(false);
        }
    };

    const onBlur = async (ev: any) => {
        if (isDirty) {
            try {
                await handleUpdateDescription(original.id, ev.target.value);
                setIsDirty(false);
            } catch (err) {
                setValue(initialValue);
                setIsDirty(false);
            }
        }
        setIsEditing(false);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // EDITABLE DESCRIPTION
    if (user?.constants?.editable_description_products?.includes(original.code) && !roles(user?.constants?.external_roles)) {
        if (isEditing) {
            return (
                <div className="flex items-center gap-x-1">
                    <input type="text" onBlur={onBlur} onChange={onChange} />
                    <MdClose className="text-xl cursor-pointer" onClick={() => setIsEditing(false)} />
                </div>
            );
        } else {
            return (
                <div className="flex items-center gap-x-1 cursor-pointer" onClick={() => setIsEditing(true)}>
                    <span>{value}</span>
                    <Tooltip
                        label={t("Inserisci descrizione")}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50
                        }}
                    >
                        <span>
                            <FaPencilAlt />
                        </span>
                    </Tooltip>
                </div>
            );
        }
    } else {
        // SERVER DESCRIPTIONp
        return (
            <>
                {
                    original.isinpricelistrb ?
                        <a href={`https://www.rothoblaas.com/search?q=${original.code}&s=eservice`} target="_blank" className="hover:underline" rel="noreferrer">{original.description.toUpperCase()}</a>
                        :
                        <span>{original.description.toUpperCase()}</span>
                }
            </>
        );
    }
};

export default EditableCellDescription;
