import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { getCurrencyDecimals } from "../../../../utils/getCurrencyDecimals";
import { AddShippingAdditionalCost } from "./AddShippingAdditionalCost";
import { AddShippingManualFreightCost } from "./AddShippingManualFreightCost";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { useEffect, useState } from "react";
import { Section } from "../../../Section";
import useAlternativeWarehouseFreightCost from "../../../../api/alternative-warehouse/useAlternativeWarehouseFreightCost";

type ShippingMethodsProps = {
    shippingMethods: any;
    cart: any;
    isLoading: boolean;
    error?: string;
    expeditionDays: any;
};

export function ShippingMethods({ shippingMethods, cart, isLoading, error, expeditionDays }: ShippingMethodsProps) {
    // context
    const { shipping_method, shipping_methods, free_port, manual_freight_cost, additional_freight_cost, setShippingMethod, setFreePort, setAdditionalFreightCost, setManualFreightCost, setManualShippingMethod } = useShippingContext();
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    const [restrictedItems, setRestrictedItems] = useState<any[]>([]);
    const alternativeWarehouses: any = useAlternativeWarehouseFreightCost({ cart: cart });

    const alternativeWarehousesFreightCost = alternativeWarehouses?.reduce((acc: number, obj: any) => acc + obj?.data?.data?.standard?.freightCost, 0);
    const showAlternativeFreightCostNotCalculated = alternativeWarehouses?.some((alternativeWarehouse: any) => alternativeWarehouse?.data?.data?.standard?.messages.length > 0);

    const handleRadioChange = (value: any) => {
        setShippingMethod(value);
    };

    const handleFreePortChange = (e: any) => {
        setFreePort(e.target.checked);
    };

    const currency = cart?.data?.customer?.currency;
    const numberFormat = cart ? new Intl.NumberFormat(cart?.data?.customer?.language_id, { style: "currency", currency: currency, minimumFractionDigits: getCurrencyDecimals(currency), maximumFractionDigits: getCurrencyDecimals(currency) }) : null;

    useEffect(() => {
        if (manual_freight_cost) {
            setFreePort(true);
        } else {
            setFreePort(false);
        }
    }, []);

    useEffect(() => {
        if (free_port) {
            setShippingMethod(null);
        } else {
            setAdditionalFreightCost(null);
            setManualFreightCost("");
            setManualShippingMethod(null);

            if (shipping_methods && !shipping_method) {
                setShippingMethod(shipping_methods[0]);
            }
        }
    }, [free_port]);

    const checkIsChemicalOver25 = () => {
        const filteredItems = cart?.data?.items?.filter((item: any) => item.is_chemical === true || item.is_over_25 === true);
        const itemCodes = filteredItems?.map((item: any) => item.code);

        // remove duplicated items code
        const set = new Set(itemCodes);
        const uniqueItemsCodes = Array.from(set);

        setRestrictedItems(uniqueItemsCodes);
    };

    useEffect(() => {
        checkIsChemicalOver25();
    }, []);



    return (

        <Section title={"Metodo di spedizione"}>
            <div className="mb-8 border">
                {
                    // first ternary
                    isLoading ?
                        <div className="flex justify-center p-6">
                            <ClipLoader
                                color={"black"}
                                loading={true}
                                size={38}
                                aria-label="Loading Spinner"
                            />
                        </div>
                        :
                        // second ternary
                        shippingMethods?.length > 0 && shippingMethods?.some((shippingMethod: any) => shippingMethod?.messages <= 0) ?
                            <div className="p-6 space-y-2">
                                {/* FREE PORT */}
                                {
                                    roles(["BA", "CS_Other", "CS", "Assistant"]) &&
                                    <div className="flex items-center gap-x-1">
                                        <input type="checkbox" name="porto-franco" id="porto-franco" onChange={(e) => handleFreePortChange(e)} checked={free_port} />
                                        <label htmlFor="porto-franco" className="p-0 cursor-pointer">{t("Modifica costo trasporto")}</label>
                                    </div>
                                }
                                {/* third ternary */}
                                {
                                    !free_port ?
                                        <div>
                                            <div className={"grid grid-cols-2 gap-x-4 gap-y-4 font-light"}>
                                                {/* SHIPPING METHODS */}
                                                {
                                                    shippingMethods && shippingMethods?.map((method: any) => (
                                                        method?.messages?.length > 0 && method?.freightCost === 0 ?
                                                            null :
                                                            // <div className="border px-4 py-2 flex-1" key={method.name}>
                                                            <label key={method?.name} htmlFor={`radio-${method.name}`} className={`border px-4 py-2 ${(method?.name?.startsWith("express") && restrictedItems.length > 0) ? "text-gray-200 cursor-not-allowed" : "cursor-pointer"} space-y-1`}>
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center gap-x-2">
                                                                        <input
                                                                            type="radio"
                                                                            className={method?.name === "express" && method?.freightCost === 0 ? "cursor-not-allowed" : "cursor-pointer"}
                                                                            name="shipping_method"
                                                                            id={`radio-${method.name}`}
                                                                            onChange={() => handleRadioChange(method)}
                                                                            checked={shipping_method?.name === method.name}
                                                                            disabled={(method?.name?.startsWith("express") && restrictedItems.length > 0) ? true : false}
                                                                        />
                                                                        <span className="first-letter:uppercase">
                                                                            {method.label ?? method.name}
                                                                        </span>
                                                                    </div>
                                                                    {
                                                                        method?.name === "express" && method?.freightCost === 0 ?
                                                                            null
                                                                            : <div>
                                                                                <span>{numberFormat?.format(method.freightCost)}</span>
                                                                            </div>
                                                                    }
                                                                </div>

                                                                {/* INFO */}
                                                                <div className="space-y-1">
                                                                    {
                                                                        expeditionDays[method.name] &&
                                                                        <div className="text-xs">
                                                                            {t("Tempi di consegna da {{min_days}} a {{max_days}} giorni", {
                                                                                min_days: expeditionDays[method.name]?.min_days ?? -1,
                                                                                max_days: expeditionDays[method.name]?.max_days ?? -1,
                                                                            })}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        method?.name?.startsWith("express") &&
                                                                        <div className="text-xs flex flex-col gap-2">
                                                                            {restrictedItems.length > 0 &&
                                                                                <p className="text-red-500">
                                                                                    {
                                                                                        !roles(user?.constants?.external_roles) ? t("Articolo {{item_code}} non spedibile via aerea. Scegli: Spedizione standard (se disponibile, altrimenti richiedere costo di trasporto al Customer Sevice), oppure torna indietro per rimuovere gli articoli dal carrello", { item_code: restrictedItems.join(", ") })
                                                                                            : t("Articolo {{item_code}} non spedibile via aerea. Scegli: Spedizione standard, oppure torna indietro per rimuovere gli articoli dal carrello", { item_code: restrictedItems.join(", ") })
                                                                                    }
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </label>
                                                        // </div>
                                                    ))
                                                }
                                            </div>

                                            {/* Alternative Warehouses */}
                                            {
                                                alternativeWarehousesFreightCost > 0 && !showAlternativeFreightCostNotCalculated &&
                                                <div className="py-5 flex gap-1 text-sm font-light">
                                                    <span>{t('Costo magazzini alternativi: ')}</span>
                                                    <b>{numberFormat?.format(alternativeWarehousesFreightCost)}</b>
                                                </div>
                                            }

                                            {
                                                alternativeWarehouses?.length > 0 && showAlternativeFreightCostNotCalculated &&
                                                <div className="py-5 flex gap-1 text-sm font-light">
                                                    {t('Non è stato possibile calcolare i costi di spedizionedelle sede alternative')}
                                                </div>
                                            }

                                            {/* ADDITIONAL FREIGHT COST */}
                                            {
                                                !roles(user?.constants?.external_roles) && shipping_method?.name !== "manual-freight-cost" &&
                                                <AddShippingAdditionalCost
                                                    numberFormat={numberFormat}
                                                    currency={currency}
                                                />
                                            }

                                            {/* TOTAL FREIGHT COST */}
                                            <div className="text-sm mt-4">
                                                {t("Totale")}: {numberFormat && numberFormat.format(shipping_method?.freightCost + (additional_freight_cost && additional_freight_cost !== "" ? parseFloat(additional_freight_cost) : 0) + (alternativeWarehousesFreightCost ? alternativeWarehousesFreightCost : 0))}
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <AddShippingManualFreightCost />
                                            <p className="text-xs text-red-500">{error}</p>
                                        </>
                                }
                            </div>
                            :
                            <div className="p-6 space-y-2">
                                {/* MANUAL FREIGHT COST */}
                                <div className="font-light">
                                    {t("Non è stato possibile calcolare i costi di spedizione")}
                                </div>
                                <AddShippingManualFreightCost />
                            </ div>
                }
            </div>
        </Section >
    );
}
