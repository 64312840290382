import client from "../api/client";
import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../hooks/useSelectStyles";
import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { Loader } from "../components/Loader";
import { Button } from "../components/Button";
import { ToastContainer, Slide, toast } from "react-toastify";
import useUsers from "../api/user-managment/useUsers";
import useUser from "../api/user-managment/useUser";
import Select from "react-select";
import { AvailableLanguages } from "../constants/AvailableLanguages";
import useUsersMutations from "../api/user-managment/useUserMutations";
import { Section } from "../components/Section";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

export default function UsersManagement() {
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const { user: userRoles } = useAuthContext();

    // state
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [rolesValues, setRolesValues] = useState<string[]>([]);
    const [permissionsValues, setPermissionsValues] = useState<string[]>([]);
    const [permissionsRoles, setPermissionsRoles] = useState<string[]>([]);
    const [languageId, setLanguageId] = useState<string>("");
    const [interfaceLanguageId, setInterfaceLanguageId] = useState<string>("");

    // hooks
    const { loadUsersOptions } = useUsers();
    const { user, isLoading, isFetching } = useUser(selectedUser?.id);

    // mutations
    const { saveUser, activateUser, deactivateUser, sendWelcomeMail } = useUsersMutations();

    const checkRoles = (roles: string[], userRoles: any) => {
        return roles?.some(r => (userRoles || []).includes(r));
    };

    // handle role checkboxes changes
    const handleCheckboxesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const item = e.target.name;
        setRolesValues((prevValues) => {
            if (e.target.checked) {
                return [...prevValues, item];
            } else if (!e.target.checked) {
                return prevValues.filter((value) => {
                    return value !== item;
                });
            } else {
                return [...prevValues];
            }
        });
    };

    // handle role checkboxes changes
    const handlePermissionsCheckboxesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const item = e.target.name;
        setPermissionsValues((prevValues) => {
            if (e.target.checked) {
                return [...prevValues, item];
            } else if (!e.target.checked) {
                return prevValues.filter((value) => {
                    return value !== item;
                });
            } else {
                return [...prevValues];
            }
        });
    };

    // handle user SELECT change
    const handleUserSelectChange = (inputValue: any) => {
        if (inputValue) {
            setSelectedUser(inputValue.value);
        } else {
            setSelectedUser(null);
            setRolesValues([]);
            setPermissionsValues([]);
            setPermissionsRoles([]);
            setInterfaceLanguageId("");
            setLanguageId("");
        }
    };

    // handle submit form
    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const params = {
            user_id: selectedUser.id,
            params: {
                roles: rolesValues,
                permissions: permissionsValues,
                languageId: languageId,
                interfaceLanguageId: interfaceLanguageId
            }
        };

        await toast.promise(saveUser.mutateAsync(params), {
            pending: t("Carico..."),
            success: t("Utente salvato con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("Error, something went wrong");
                    }
                }
            }
        });
    };

    const handleSendWelcomeMail = async () => {
        await toast.promise(sendWelcomeMail.mutateAsync(selectedUser.id), {
            pending: t("Carico..."),
            success: t("Mail di benvenuto inviata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("Error, something went wrong");
                    }
                }
            }
        });
    };

    const handleActivateUser = async () => {
        await toast.promise(activateUser.mutateAsync(selectedUser.id), {
            pending: t("Carico..."),
            success: t("Utente attivato con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("Error, something went wrong");
                    }
                }
            }
        });
    };

    const handleDeactivateUser = async () => {
        await toast.promise(deactivateUser.mutateAsync(selectedUser.id), {
            pending: t("Carico..."),
            success: t("Utente disattivato con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("Error, something went wrong");
                    }
                }
            }
        });
    };

    useEffect(() => {
        setRolesValues(user?.user?.roles || []);
        setPermissionsValues(user?.user?.permissions || []);
        setPermissionsRoles(user?.user?.granted_permissions.filter((x: any) => !user?.user?.permissions.includes(x)) || []);

        setInterfaceLanguageId(user?.user?.interface_language_id ?? "");
        setLanguageId(user?.user?.language_id ?? "");
    }, [user]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            <Title>{t("Gestione utenti")}</Title>

            {/* select user  */}
            <Section title={"Seleziona utente"} className="mb-10">
                <div className="w-1/2">
                    <label htmlFor="">{t("Nome o e-mail utente")}</label>
                    <AsyncSelect
                        loadOptions={loadUsersOptions}
                        onChange={handleUserSelectChange}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        styles={CustomStyles}
                        placeholder={t("inserisci nome o e-mail dell'utente")}
                        loadingMessage={() => t("Caricamento in corso...")}
                        noOptionsMessage={() => t("Nessun risultato")}
                        openMenuOnClick={false}
                        isClearable
                        escapeClearsValue
                    />
                </div>
            </Section>

            {((isLoading && isFetching) || saveUser.isLoading || deactivateUser.isLoading || activateUser.isLoading || sendWelcomeMail.isLoading) &&
                <Loader />
            }
            {selectedUser &&
                <Section title={selectedUser.name}>
                    <form onSubmit={handleSubmit}>
                        {/* LANGUAGE SELECTS */}
                        <div className="space-y-6">
                            <div className={"flex flex-wrap gap-6"}>
                                <div className={"flex-1 whitespace-nowrap"}>
                                    <label htmlFor="">{t("Lingua contenuti")}:</label>
                                    <Select
                                        styles={CustomStyles}
                                        options={AvailableLanguages as any}
                                        onChange={(ev: any) => setLanguageId(ev.value)}
                                        escapeClearsValue
                                        value={AvailableLanguages.filter((el) => el.value === languageId)}
                                    />
                                </div>
                                <div className={"flex-1 whitespace-nowrap"}>
                                    <label htmlFor="">{t("Lingua interfaccia")}:</label>
                                    <Select
                                        styles={CustomStyles}
                                        options={AvailableLanguages as any}
                                        onChange={(ev: any) => setInterfaceLanguageId(ev.value)}
                                        escapeClearsValue
                                        value={AvailableLanguages.filter((el) => el.value === interfaceLanguageId)}
                                    />
                                </div>
                            </div>

                            {/* CUSTOMERS LIST */}
                            {checkRoles(userRoles?.constants?.external_roles, selectedUser?.roles || []) && <div>
                                <h3 className='mb-2'>{t("Clienti")}:</h3>
                                <ul>
                                    {user?.customers?.map((customer: any, index: number) => {
                                        return <li className="ml-5 list-disc font-light" key={index}>{`${customer.name} - ${customer.code} - ${customer.city} - ${customer.ts_fullname}`}</li>;
                                    })}
                                </ul>
                            </div>}

                            {/* ROLES */}
                            <div>
                                <h3 className='mb-2'>{t("Ruoli")}:</h3>
                                <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-2 font-light'>
                                    {user?.all_roles?.map((role: any, index: number) => (
                                        <div key={index}>
                                            <label className='cursor-pointer'>
                                                <input
                                                    type="checkbox"
                                                    name={role}
                                                    value={role}
                                                    id={role}
                                                    checked={rolesValues.includes(role)}
                                                    onChange={(e) => {
                                                        handleCheckboxesChange(e);
                                                    }}
                                                    className='mr-1 accent-black'
                                                />
                                                <span>{t(role)}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* PERMISSIONS */}
                            <div>
                                <h3 className='mb-2'>{t("Permessi")}:</h3>
                                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-2 font-light'>
                                    {user?.all_permissions?.map((permission: any, index: number) => (
                                        <div key={index}>
                                            <label className='cursor-pointer'>
                                                <input
                                                    type="checkbox"
                                                    name={permission}
                                                    value={permission}
                                                    id={permission}
                                                    disabled={permissionsRoles.indexOf(permission) >= 0}
                                                    checked={permissionsValues.includes(permission) || permissionsRoles.indexOf(permission) >= 0}
                                                    onChange={(e) => {
                                                        handlePermissionsCheckboxesChange(e);
                                                    }}
                                                    className='mr-1 accent-black'
                                                />
                                                {t(permission)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* ACTIONS */}
                        <div className='mt-4 text-right space-x-4'>
                            {checkRoles(userRoles?.constants?.external_roles, selectedUser?.roles || []) && (
                                !user?.user?.isActive ? (
                                    <Button type="button" variant={Button.variant.secondary} onClick={async () => await handleActivateUser()}>
                                        {t("Attiva")}
                                    </Button>
                                ) : (
                                    <>
                                        <Button type="button" variant={Button.variant.secondary} onClick={async () => await handleSendWelcomeMail()}>
                                            {t("Invia mail di benvenuto")}
                                        </Button>
                                        <Button type="button" variant={Button.variant.secondary} onClick={async () => await handleDeactivateUser()}>
                                            {t("Disattiva")}
                                        </Button>
                                    </>
                                )
                            )}
                            <Button variant={Button.variant.primary}>
                                {t("Salva")}
                            </Button>
                        </div>
                    </form>
                </Section>
            }
        </div>
    );
}
