import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Tooltip } from "@reach/tooltip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, Slide, toast } from "react-toastify";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Button } from "../Button";
import { Modal } from "../commom/Modal";
import { Loader } from "../Loader";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { convertMiliseconds, getExpiredDays } from "../../utils/utils";
import { HiOutlineDotsVertical } from "react-icons/hi";
import useBlockedOrder from "../../api/orders/useBlockedOrder";
import DuplicationModal from "../templates/DuplicationModal";
import Table from "../Table";

export const OrdersTableActions = (props: any) => {
    const data = props?.row?.original;

    // hooks
    const { t } = useTranslation();
    const { user, can, roles } = useAuthContext();
    const { unblockOrCancelOrder, createOrder, resendOrderToSemiramis, deleteOrder } = useOrderMutations();

    // state
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const [unblockReason, setUnblockReason] = useState("");
    const [reasonError, setReasonError] = useState<null | string>(null);
    const [openDuplicateOrder, setOpenDuplicateOrder] = useState<any>(false);
    const [cancelOrder, setCancelOrder] = useState<any>(false);

    // react query
    const { blockedOrder } = useBlockedOrder(props.row.original.id, openUnblockModal);

    // constants
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);
    const totalBlockedAmount = blockedOrder?.data?.customer?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.total_net_price), 0);
    const residualCredit = blockedOrder?.data?.customer?.finance?.creditlimit - blockedOrder?.data?.customer?.finance?.unpaid - blockedOrder?.data?.customer?.finance?.undelivered - blockedOrder?.data?.customer?.finance?.deliverednotinvoiced - blockedOrder?.data?.customer?.finance?.invoicednottransferred - totalBlockedAmount;

    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice"
                    ? getExpiredDays(props.row.original.due_date) > 0
                        ? convertMiliseconds(
                            getExpiredDays(props.row.original.due_date),
                            "d"
                        )
                        : ""
                    : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    let numberFormat: any = null;
    try {
        numberFormat = new Intl.NumberFormat(data?.customer?.language_id, { style: "currency", currency: data?.customer?.currency, minimumFractionDigits: getCurrencyDecimals(data?.customer?.currency), maximumFractionDigits: getCurrencyDecimals(data?.customer?.currency) });
    } catch (e) {
        console.log("Error Catched", data?.customer);
    }

    const handleUnblockReasonChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setUnblockReason(e.target.value);

        // clear state if there's no more error
        if (unblockReason.length > 20) {
            return setReasonError(null);
        }
    };

    // FE validation
    const handleUnblockReasonBlur = () => {
        if (unblockReason.length <= 0) {
            return setReasonError(t("La motivazione è obbligatoria"));
        } else if (unblockReason.length < 20) {
            return setReasonError(
                t("La motivazione deve avere minimo 20 caratteri")
            );
        } else {
            return setReasonError(null);
        }
    };

    const handleUnblockOrder = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        if (reasonError) return;

        await toast.promise(
            unblockOrCancelOrder.mutateAsync({
                order_id: data.id,
                action: "unblock",
                reason: unblockReason,
            }),
            {
                success: t("Ordine sbloccato con successo"),
                error: {
                    render({ data }: any) {
                        if (data?.response?.status === 403) {
                            return t(data?.response?.data?.message);
                        } else {
                            return t("Qualcosa è andato storto");
                        }
                    },
                },
            }
        );

        setOpenUnblockModal(false);
    };

    const handleCancelOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (reasonError) return;

        try {
            await unblockOrCancelOrder.mutateAsync({
                order_id: data.id,
                action: "cancel",
                reason: unblockReason,
            });
            toast.success(t("Ordine stornato con successo"));
            setOpenUnblockModal(false);
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    const handleSendOrder = async () => {
        try {
            await resendOrderToSemiramis.mutateAsync({ order_id: data.id });
            toast.success(t("Ordine processato correttamente"));
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    const handleDeleteOrder = async () => {
        try {
            await deleteOrder.mutateAsync({ order_id: data.id });
            toast.success(t("Ordine eliminato"));
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    return (
        // first condition checks if the order is blocked and the second if it has been sent to semiramis or not
        <>
            {(unblockOrCancelOrder.isLoading || createOrder.isLoading || deleteOrder.isLoading || resendOrderToSemiramis.isLoading) && <Loader />}

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {/* DUPLICATE ORDER MODAL  */}
            <DuplicationModal
                label={t("Duplica ordine")}
                modalState={openDuplicateOrder}
                setModalState={setOpenDuplicateOrder}
                data={data}
                type="order"
            />

            {
                cancelOrder &&
                <Modal title={t("Cancella ordine")} open={cancelOrder} onClose={setCancelOrder}>
                    <p>{t("Cancella ordine dalla lista?")}</p>
                    <div className="text-right space-x-2 mt-4">
                        <Button variant={Button.variant.text} onClick={() => setCancelOrder(false)}>{t("Annulla")}</Button>
                        <Button variant={Button.variant.primary} onClick={() => handleDeleteOrder()}>{t("Procedi")}</Button>
                    </div>
                </Modal>
            }

            {
                openUnblockModal && (
                    <Modal
                        open={openUnblockModal}
                        title={t("Sblocca ordine")}
                        onClose={setOpenUnblockModal}
                    >
                        <div className="space-y-4">
                            <p className="font-normal">
                                {t("Attenzione: aggiornamento dati in tempo reale, i valori potrebbero differire dalla mail di sblocco")}.
                            </p>
                            {/* FINANCIAL INFO */}
                            <div className="border p-4 space-y-2">
                                <div className="font-normal">
                                    {t(
                                        "Ordine E-Service {{order_id}} bloccato per {{block_reason}}",
                                        {
                                            order_id: blockedOrder?.data?.id,
                                            block_reason: blockedOrder?.data?.block_reason === "creditlimit" ? t("Fuori fido").toLocaleLowerCase() : t("Insoluto").toLocaleLowerCase(),
                                        }
                                    )}
                                </div>
                                <div>
                                    {t("Cliente")}:{" "}
                                    {blockedOrder?.data?.customer?.name} -{" "}
                                    {blockedOrder?.data?.customer?.code}
                                </div>
                                <p>
                                    {t("Insoluto")}:{" "}
                                    <span className={`${blockedOrder?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                                        {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaidexpired) : ""}
                                    </span>
                                </p>
                                <p>
                                    {t("Partite aperte")}:{" "}
                                    {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaid) : ""}
                                </p>
                                <p>
                                    {t("Fido")}:{" "}
                                    {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.creditlimit) : ""}
                                </p>
                                <p>
                                    {t("Ordine attuale")}:{" "}
                                    {numberFormat ? numberFormat?.format(blockedOrder?.data?.total_net_price) : ""}
                                </p>
                                <p>
                                    {t("Totale ordini bloccati E-service")}:{" "}
                                    {numberFormat?.format(totalBlockedAmount)}
                                </p>
                                <p>
                                    {t("Fido residuo")}:{" "}
                                    <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                        {numberFormat ? numberFormat?.format(residualCredit) : ""}
                                    </span>
                                </p>
                                <p>
                                    {t("Totale fatture ivate ultimi 24 mesi")}:{" "}
                                    <span>
                                        {numberFormat ? numberFormat.format(blockedOrder?.data?.customer?.customerInvoices?.reduce((acc: number, obj: any) => acc + obj.netamount, 0)) : ""}
                                    </span>
                                </p>
                                <p>
                                    {t("Rothoblaas Rating")}:{" "}
                                    <span className={blockedOrder?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                        {blockedOrder?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                        {blockedOrder?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                        {blockedOrder?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                        {blockedOrder?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                    </span>
                                </p>
                                <p>
                                    {t("Finance Rating")}:{" "}
                                    <span
                                        className={blockedOrder?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                        {blockedOrder?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                        {blockedOrder?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                        {blockedOrder?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                        {blockedOrder?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                    </span>
                                </p>
                                <p>
                                    {t("TS")}:{" "}
                                    {blockedOrder?.data?.customer?.technicalSale?.name}
                                </p>
                                <p>
                                    {t("ASM")}:{" "}
                                    {blockedOrder?.data?.customer?.areaManager?.name}
                                </p>

                                {blockedOrder && (
                                    <Table
                                        variants={Table.variants.expandable}
                                        label="Estratto conto"
                                        data={
                                            blockedOrder?.data?.customer
                                                ?.open_nav_payments
                                        }
                                        columns={invoicesColumns}
                                        emptyStateMessage="Nessuna fattura aperta"
                                    />
                                )}
                            </div>

                            {/* REASON */}
                            <div>
                                <label>
                                    {t("Inserisci la motivazione dello sblocco/storno:")}
                                </label>
                                <textarea
                                    className={`${reasonError && "border-rose-500 outline-rose-500"}`}
                                    value={unblockReason}
                                    onChange={(e) => { handleUnblockReasonChange(e); }}
                                    onBlur={handleUnblockReasonBlur}
                                />
                                {reasonError && (<p className="text-rose-500">{reasonError}</p>)}
                            </div>

                            <div className="text-right space-x-2">
                                <Button
                                    variant={Button.variant.text}
                                    onClick={(e) => handleCancelOrder(e)}
                                >
                                    {t("Nega")}
                                </Button>
                                <Button
                                    variant={Button.variant.primary}
                                    onClick={(e) => handleUnblockOrder(e)}
                                >
                                    {t("Autorizza")}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}

            {/* <Button variant={Button.variant.secondary} onClick={() => setOpenUnblockModal(true)}>
                Sblocca/Storna
            </Button> */}

            <Menu>
                <MenuButton>
                    <Tooltip
                        label={"actions"}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                        }}
                    >
                        <span>
                            <HiOutlineDotsVertical className="text-lg cursor-pointer" />
                        </span>
                    </Tooltip>
                </MenuButton>

                <MenuList className="offers-dropdown">
                    <MenuItem
                        className="offers-dropdown-items offers-dropdown-items-selected"
                        onSelect={() => setOpenDuplicateOrder(true)}
                    >
                        {t("Duplica ordine")}
                    </MenuItem>
                    {((!roles(user?.constants?.external_roles) &&
                        !data?.semiramis_number &&
                        !data?.canceled_at) ||
                        (data?.can_be_unblocked && data?.blocked && !data?.canceled_at)) && (
                            <>
                                {data?.can_be_unblocked && data?.blocked && (
                                    <MenuItem
                                        className="offers-dropdown-items offers-dropdown-items-selected"
                                        onSelect={() => setOpenUnblockModal(true)}
                                    >
                                        {t("Sblocca ordine")}
                                    </MenuItem>
                                )}
                                {!data?.semiramis_number && !data?.blocked && (
                                    <MenuItem
                                        className="offers-dropdown-items offers-dropdown-items-selected"
                                        onSelect={handleSendOrder}
                                    >
                                        {t("Rinvia ordine")}
                                    </MenuItem>
                                )}
                                {!data?.semiramis_number &&
                                    can(["can_delete_orders"]) && (
                                        <MenuItem
                                            className="offers-dropdown-items offers-dropdown-items-selected"
                                            onSelect={() => setCancelOrder(true)}
                                        >
                                            {t("Elimina ordine")}
                                        </MenuItem>
                                    )}
                            </>
                        )}
                </MenuList>
            </Menu>
        </>
    );
};
