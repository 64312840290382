import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import client from "../../api/client";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";

type CartClientInfoProps = {
    data: any
};

export const CartClientInfo = ({ data }: CartClientInfoProps) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    const [isEditing, setIsEditing] = useState(false);
    const [openShippingAddress, setOpenShippingAddress] = useState(false);

    const { register, handleSubmit, unregister, formState: { errors } } = useForm();
    const customer = data?.data?.customer;

    const handleSubmitheading = async (formData: any) => {
        const params = {
            heading: formData
        };

        try {
            await client.put(`carts/${data?.data?.id}/heading`, params);

            queryClient.invalidateQueries(["cart"]);

            setIsEditing(false);
            toast.success(t("Intestazione modificata con successo"));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (data?.data?.heading?.shippingAddress) {
            setOpenShippingAddress(true);
        } else {
            setOpenShippingAddress(false);
        }
    }, [data]);

    useEffect(() => {
        if (data?.data?.customer?.type === "DUMMY CUSTOMER" && !data?.data?.heading && !data?.data?.offer_state) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [data]);

    useEffect(() => {
        if (!openShippingAddress) {
            unregister(["shippingAddress.name", "shippingAddress.address", "shippingAddress.postalcode", "shippingAddress.city"]);
        }
    }, [openShippingAddress]);

    if (!data?.data?.customer) return null;

    return (
        <div className="p-6 mb-8 border text-base bg-neutral-weaker">
            {
                !isEditing ?
                    <div className="font-light space-y-2">
                        <h2 className="text-lg font-sans text-fg-lighter font-normal">{t("Cliente")}</h2>

                        {/* customer name  */}
                        <div className="uppercase">
                            {data?.data?.heading ? `${data?.data?.heading?.name}` : `${data?.data?.customer?.name}`}
                        </div>

                        {/* customer code */}
                        {
                            !data?.data?.heading &&
                            <div className="space-x-1 uppercase">
                                <span>
                                    {t("Codice cliente")}:
                                </span>
                                <span>
                                    {`${data?.data?.customer?.code}`}
                                </span>
                            </div>
                        }

                        {/* doc notes */}
                        {
                            data?.data?.client_reference &&
                            <div className="space-x-1 uppercase">
                                <span>
                                    {t("Note documenti commerciali")}:
                                </span>
                                <span>
                                    {data?.data?.client_reference}
                                </span>
                            </div>
                        }

                        {/* address */}
                        <div className="space-x-1 uppercase">
                            <span>{t("Indirizzo committente")}:</span>
                            <span>
                                {
                                    !data?.data?.heading ?
                                        `${customer?.name} - ${customer?.street}, ${customer?.city} ${customer?.region_id} ${customer?.postalcode} ${customer?.country_id}`
                                        :
                                        `
                                    ${data?.data?.heading?.address ? data?.data?.heading?.address?.toUpperCase() : ""}${data?.data?.heading?.city ? "," : ""}
                                    ${data?.data?.heading?.city ? data?.data?.heading?.city?.toUpperCase() : ""}
                                    ${data?.data?.heading?.postalcode ? data?.data?.heading?.postalcode?.toUpperCase() : ""}
                                `
                                }
                            </span>
                        </div>

                        {/* SHIPPING ADDRESS */}
                        {
                            data?.data?.heading?.shippingAddress &&
                            <div className="space-x-1 uppercase">
                                <span>{t("Indirizzo di consegna")}:</span>
                                <span>
                                    {
                                        `
                                    ${data?.data?.heading?.shippingAddress?.address ? data?.data?.heading?.shippingAddress?.address?.toUpperCase() : ""}${data?.data?.heading?.shippingAddress?.city ? "," : ""}
                                    ${data?.data?.heading?.shippingAddress?.city ? data?.data?.heading?.shippingAddress?.city?.toUpperCase() : ""}
                                    ${data?.data?.heading?.shippingAddress?.postalcode ? data?.data?.heading?.shippingAddress?.postalcode?.toUpperCase() : ""}
                                `
                                    }
                                </span>
                            </div>
                        }

                        {/* invoice address */}
                        {
                            customer.invoicing?.code !== customer.code &&
                            <div className="space-x-1 uppercase">
                                <span>{t("Indirizzo di fatturazione")}:</span>
                                <span>
                                    {`${customer?.invoicing?.name} - ${customer?.invoicing?.street}, ${customer?.invoicing?.city} ${customer?.invoicing?.postalcode} ${customer?.invoicing?.country_id}`}
                                </span>
                            </div>
                        }

                        {/* TS */}
                        {
                            !roles(user?.constants?.external_roles) &&
                            <div className="space-x-1 uppercase">
                                <span>{t("Technical Salesman")}:</span>
                                <span>
                                    {`${customer?.ts_code} - ${customer?.ts_fullname}`}
                                </span>
                            </div>
                        }

                        { /* EDIT shown only if dummy customer */ }
                        {
                            data?.data?.customer?.type === "DUMMY CUSTOMER" && !data?.data?.offer_state &&
                            <div className="pt-6">
                                <hr />
                                <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setIsEditing(true)}>
                                    <FaPen />
                                    <div className="font-normal font-sans text-base">
                                        {t("Modifica")}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    :

                    // EDITING TRUE
                    <form onSubmit={handleSubmit(handleSubmitheading)} className="space-y-2">
                        <div className="flex items-center justify-between">
                            <h2 className="text-lg font-sans text-fg-lighter font-normal">{t("Dati cliente")}</h2>

                            <MdClose
                                onClick={() => setIsEditing(false)}
                                className="text-lg cursor-pointer hover:scale-110"
                            />

                        </div>

                        {/* NAME */}
                        <div>
                            <label htmlFor="name">{t("Nome cliente")}:</label>
                            <input {...register("name", { required: `${t("- questo campo è obbligatorio")}` })} type="text" id='name' name='name' placeholder={t("inserisci il nome")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.name} />
                        </div>
                        <p className="text-xs text-rose-500">{errors ? errors["name"]?.message : null}</p>

                        {/* ADDRESS */}
                        <div>
                            <label htmlFor="address">{t("Indirizzo")}:</label>
                            <input {...register("address", { required: `${t("- questo campo è obbligatorio")}` })} type="text" id='address' name='address' placeholder={t("inserisci la via")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.address} />
                        </div>
                        <p className="text-xs text-rose-500">{errors ? errors["address"]?.message : null}</p>

                        <div className="flex justify-between gap-x-4">
                            {/* CITY */}
                            <div className='flex-1'>
                                <label htmlFor="city">{t("Città")}:</label>
                                <input {...register("city")} type="text" id='city' name='city' placeholder={t("inserisci la città")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.city} />
                            </div>

                            {/* POSTALCODE */}
                            <div className='flex-1'>
                                <label htmlFor="postalcode">{t("CAP")}:</label>
                                <input {...register("postalcode")} type="text" id='postalcode' name='postalcode' placeholder={t("inserisci il CAP")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.postalcode} />
                            </div>
                        </div>

                        <div className="flex items-center gap-x-1 hover:underline cursor-pointer" onClick={() => setOpenShippingAddress(!openShippingAddress)}>
                            {openShippingAddress ? <AiOutlineMinus /> : <AiOutlinePlus />}
                            {!openShippingAddress ? t("Aggiungi indirizzo di consegna") : t("Rimuovi indirizzo di consegna")}
                        </div>

                        {
                            openShippingAddress &&
                            <div className="space-y-2">
                                {/* NAME */}
                                <div>
                                    <label htmlFor="name">{t("Nome cliente")}:</label>
                                    <input {...register("shippingAddress.name", { required: `${t("- questo campo è obbligatorio")}` })} type="text" id='name' placeholder={t("inserisci il nome")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.shippingAddress?.name} />
                                </div>
                                <p className="text-xs text-rose-500">{(errors?.shippingAddress as any)?.name && (errors?.shippingAddress as any).name?.message}</p>

                                {/* ADDRESS */}
                                <div>
                                    <label htmlFor="address">{t("Indirizzo")}:</label>
                                    <input {...register("shippingAddress.address", { required: `${t("- questo campo è obbligatorio")}` })} type="text" id='address' placeholder={t("inserisci la via")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.shippingAddress?.address} />
                                </div>
                                <p className="text-xs text-rose-500">{(errors?.shippingAddress as any)?.address && (errors?.shippingAddress as any).address?.message}</p>

                                <div className="flex justify-between gap-x-4">
                                    {/* CITY */}
                                    <div className='flex-1'>
                                        <label htmlFor="city">{t("Città")}:</label>
                                        <input {...register("shippingAddress.city")} type="text" id='city' placeholder={t("inserisci la città")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.shippingAddress?.city} />
                                    </div>
                                    {/* POSTALCODE */}
                                    <div className='flex-1'>
                                        <label htmlFor="postalcode">{t("CAP")}:</label>
                                        <input {...register("shippingAddress.postalcode")} type="text" id='postalcode' placeholder={t("inserisci il CAP")} className='placeholder:text-sm' defaultValue={data?.data?.heading?.shippingAddress?.postalcode} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="text-right space-x-2">
                            <Button variant={Button.variant.text} type="button" onClick={() => setIsEditing(false)}>{t("Annulla")}</Button>
                            <Button variant={Button.variant.primary}>{t("Salva")}</Button>
                        </div>
                    </form>
            }
        </div>

    );
};
