import classnames from "classnames";
import { ReactNode } from "react";

type StatusProps = {
    variant: StatusVariant;
    children?: ReactNode
};

export enum StatusVariant {
    green = "green",
    yellow = "yellow",
    red = "red",
    amber = "amber",
    blue = "blue",
    violet = "violet",
    brown = "brown",
    gray = "gray",
    circled_green = "circled_green",
    circled_red = "circled_red",
}

export const VARIANT_MAPS = {
    yellow: "bg-yellow-400 h-2.5 w-8 rounded-full",
    red: "bg-red-500 h-2.5 w-8 rounded-full",
    green: "bg-lime-500 h-2.5 w-8 rounded-full",
    amber: "bg-amber-600 h-2.5 w-8 rounded-full",
    blue: "bg-sky-600 h-2.5 w-8 rounded-full",
    violet: "bg-violet-600 h-2.5 w-8 rounded-full",
    brown: "bg-brown-600 h-2.5 w-8 rounded-full",
    gray: "bg-gray-200 h-2.5 w-8 rounded-full",
    circled_green: "bg-lime-500 h-2 w-2 rounded-full",
    circled_red: "bg-red-500 h-2 w-2 rounded-full",
};

Status.variant = StatusVariant;

export function Status({ variant, children }: StatusProps) {
    return (
        <div className='flex items-center'>
            {
                children ?
                    <div className='mr-1 whitespace-nowrap'>
                        {children}
                    </div>
                    : null
            }
            <div className={classnames(VARIANT_MAPS[variant])}></div>
        </div>
    );
}
