import { useTranslation } from "react-i18next";
import { getCurrencyDecimals } from "../../../utils/getCurrencyDecimals";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";

export default function FinancialInfo({ cart }: any) {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    if (
        cart?.data?.customer?.unprocessedOrders &&
        cart?.data?.customer?.finance?.unpaidexpired &&
        cart?.data?.customer?.finance?.unpaid &&
        cart?.data?.customer?.finance?.creditlimit &&
        cart?.data?.customer?.finance?.undelivered &&
        cart?.data?.customer?.finance?.deliverednotinvoiced &&
        cart?.data?.customer?.finance?.invoicednottransferred
    ) {
        const numberFormat = cart?.data?.customer ? new Intl.NumberFormat(cart?.data?.customer?.language_id, { style: "currency", currency: cart?.data?.customer?.currency, minimumFractionDigits: getCurrencyDecimals(cart?.data?.customer?.currency) }) : null;

        const totalBlockedAmount = cart?.data?.customer?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.total_net_price), 0);
        const residualCredit = cart?.data?.customer.finance.creditlimit - cart?.data?.customer.finance.unpaid - cart?.data?.customer.finance.undelivered - cart?.data?.customer.finance.deliverednotinvoiced - cart?.data?.customer.finance.invoicednottransferred - totalBlockedAmount;

        return (
            <div>
                <div className="text-sm mb-1">{t("Dati finanziari")}</div>

                <div className={`bg-white ${!roles(user?.constants?.external_roles) ? "border grid sm:grid-cols-2 md:grid-cols-6" : "border grid sm:grid-cols-2"}`}>
                    <div className="flex flex-col justify-center p-2 text-sm gap-y-2 border-b md:border-b-0 sm:border-r">
                        <div>
                            {t("Insoluto")}
                        </div>
                        <div className={`font-light ${cart?.data?.customer.finance.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                            {numberFormat && numberFormat.format(cart?.data?.customer.finance.unpaidexpired)}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center p-2 text-sm gap-y-2 border-b md:border-b-0 md:border-r">
                        <div>
                            {t("Partite aperte")}
                        </div>
                        <div className="font-light">
                            {numberFormat && numberFormat.format(cart?.data?.customer.finance.unpaid)}
                        </div>
                    </div>
                    {
                        !roles(user?.constants?.external_roles) &&
                        <>
                            <div className="flex flex-col justify-center p-2 text-sm gap-y-2 border-b md:border-b-0 sm:border-r">
                                <div>
                                    {t("Fido")}
                                </div>
                                <div className="font-light">
                                    {numberFormat && numberFormat.format(cart?.data?.customer.finance.creditlimit)}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center p-2 text-sm gap-y-2 border-b md:border-b-0 md:border-r">
                                <div>
                                    {t("Fido residuo")}
                                </div>
                                <div className={`font-light ${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                    {numberFormat && numberFormat.format(residualCredit)}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center p-2 text-sm gap-y-2 border-b sm:border-b-0 sm:border-r">
                                <div>
                                    Rothoblaas Rating
                                </div>
                                <p className={cart?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : cart?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                    {cart?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                    {cart?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                    {cart?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                    {cart?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                </p>
                            </div>
                            <div className="flex flex-col justify-center p-2 text-sm gap-y-2">
                                <div>
                                    Finance Rating
                                </div>
                                <p className={cart?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : cart?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                    {cart?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                    {cart?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                    {cart?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                    {cart?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                </p>
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
