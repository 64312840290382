import { useQueries } from "@tanstack/react-query";
import client from "../client";

type useAlternativeWarehouseFreightCostParams = { cart: any };

export default function useAlternativeWarehouseFreightCost(params: useAlternativeWarehouseFreightCostParams) {

    const fetchFreightCost = async (storagearea_id: string) => {
        const res = await client.get("shipping/freight-cost", {
            params: {
                cart_id: params.cart.data.id,
                storagearea_id: storagearea_id,
                isAlternativeWarehouse: 1
            }
        });
        return res.data;
    };

    const queriesArray = params?.cart?.data?.selectedAlternativeWarehouses?.map((storagearea_id: any) => {
        return {
            queryKey: ['alternativeFreightCost', storagearea_id],
            queryFn: () => fetchFreightCost(storagearea_id),
            refetchOnWindowFocus: false,
        }
    })

    const results = useQueries({
        queries: queriesArray ? queriesArray : []
    });


    return results;
}
