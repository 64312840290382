import client from "../client";
import { useQuery } from "@tanstack/react-query";

type useAlternativeWarehouseParams = {
    cartId: string,
    cartItem: string,
};

export default function useAlternativeWarehouse(params: useAlternativeWarehouseParams) {

    const fetchAlternativeWarehouse = async (params: useAlternativeWarehouseParams) => {
        const res = await client.get(`carts/${params.cartId}/items/${params.cartItem}/warehouse`);
        return res.data;
    };

    const { data, isLoading, isFetching } = useQuery({
        queryKey: ['alternativeWarehouse', params.cartId, params.cartItem],
        queryFn: () => fetchAlternativeWarehouse(params),
        refetchOnWindowFocus: false
    })

    return { data, isLoading, isFetching };
}
