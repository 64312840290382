import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { Item } from "../../interfaces/Item";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import OfferTable from "./OfferTable";
import { useNavigate, useParams } from "react-router-dom";
import useOffer from "../../api/offers/useOffer";
import useOfferItemsMutations from "../../api/offers/useOfferItemsMutations";
import { AddProductForm } from "../commom/AddProductForm";
import { CustomerForm } from "../commom/CustomerForm";
import client from "../../api/client";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { Modal } from "../commom/Modal";
import { toast, ToastContainer, Slide } from "react-toastify";
import useGetCustomers from "../../api/customers/useGetCustomers";
import debounce from "lodash.debounce";
import { Message } from "../Message";
import useCheckOfferPricesMutations from "../../api/offers/useCheckOfferPricesMutations";
import { Loader } from "../Loader";
import useCheckOfferPrices from "../../api/offers/useCheckOfferPrices";
import useCartOfferColumns from "../../hooks/useCartOfferColumns";
import Switch from "../commom/Switch";

export const OfferProducts = ({ setTitle }: any) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // context
    const { can, roles, user, showMarginColumns, setShowMarginColumns } = useAuthContext();
    const { resetShippingContext, client_reference } = useShippingContext();

    // state
    const [openItem, setOpenItem] = useState<boolean>(false);
    const [openEmptyList, setOpenEmptyList] = useState<boolean>(false);
    const [isTableValid, setIsTableValid] = useState<any>([]);
    const [disableAddButton, setDisableAddButton] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [qty, setQty] = useState(0);
    const [discount1, setDiscount1] = useState("0");
    const [discount2, setDiscount2] = useState("0");
    const [openUpdatedProductsMessage, setOpenUpdatedProductsMessage] = useState<boolean>(false);
    const [openPriceChangesMessage, setOpenPriceChangesMessage] = useState<boolean>(false);
    const [OpenConfirmUpdatePrices, setOpenConfirmUpdatePrices] = useState<boolean>(false);
    const [isOfferValid, setIsOfferValid] = useState<boolean>(false);

    // refs
    const customerSelect: any = useRef();
    const addProductFormRef: any = useRef();

    // react query
    const { loadCustomersOptions } = useGetCustomers();
    const { updateOffer } = useOffersMutations();
    const { data: offer, selectedCustomer, setSelectedCustomer, isLoading, isFetching } = useOffer(id);
    const { priceChanges, isLoading: isPriceChangesLoading, isFetching: isPriceChangesFetching } = useCheckOfferPrices(id, !!offer?.data?.is_dirty);
    const { updatedPrices, updatePrices, updatePricesLoading } = useCheckOfferPricesMutations();

    // hooks
    const { addOfferProduct, deleteAllOfferProducts } = useOfferItemsMutations();
    const columns = useCartOfferColumns(offer, showMarginColumns);

    // handle show margin change
    const handleShowMarginColumnsChange = (ev: any) => {
        setShowMarginColumns(ev.target.checked);
    };

    // handle customer SELECT change
    const handleCustomerChange = async (inputValue: any) => {
        // if customer changes set cart items and shipping info to original states
        if (inputValue) {
            resetShippingContext();

            setSelectedCustomer(inputValue);

            const params = {
                cart_id: offer?.data?.id,
                body: {
                    customer_code: inputValue?.code ?? null,
                }
            };

            await updateOffer.mutateAsync(params);

            setQty(0);
            setDiscount1("0");
            setDiscount2("0");
        } else {
            setSelectedCustomer(null);
        }
    };

    // handle item search INPUT change
    const handleItemSearchInputChange = (value: any) => {
        if (value === "") {
            setOpenItem(false);
        } else {
            setOpenItem(true);
        }
    };

    // handle item search SELECT change
    const handleItemSearchChange = async (inputValue: any) => {
        if (inputValue) {
            const params = {
                "filter[customer_code]": offer?.data?.customer.code
            };


            setDisableAddButton(true);

            const res = await client.get(`products/${inputValue.value.code}/pricing`, {
                params: params
            });

            setDiscount1(parseFloat(res.data.data.discount_1).toFixed(2));
            setDiscount2(parseFloat(res.data.data.discount_2).toFixed(2));

            setDisableAddButton(false);

            setQty(parseInt(inputValue.value.minsellable));
        }

        setSelectedItem(inputValue?.value);
    };

    // handle click to empty table
    const handleEmptyClick = async () => {
        await deleteAllOfferProducts.mutateAsync({ cart_id: offer?.data?.id });
        setOpenEmptyList(false);
    };

    // handle next step click
    const handleNextStepClick = async () => {
        const params = {
            cart_id: offer?.data?.id,
            body: {
                customer_code: offer?.data?.customer.code,
                client_reference
            }
        };

        await updateOffer.mutateAsync(params);

        navigate("shipping");
    };

    // handle form submit
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisableAddButton(true);

        // send discoutn 1 & 2 only if user is not a client
        const body = {
            product_code: selectedItem?.code,
            quantity: Number(qty),
            discount_1: Number(discount1).toFixed(2),
            discount_2: Number(discount2).toFixed(2),
        };

        try {
            await toast.promise(addOfferProduct.mutateAsync({ cart_id: offer?.data?.id, body: body }), {
                pending: t("Carico..."),
                success: t("Modifica effettuata con successo"),
                error: {
                    render({ data }: any) {
                        if (data?.response?.status === 403) {
                            return t(data?.response?.data?.message);
                        } else {
                            return t("Error, something went wrong");
                        }
                    }
                }
            });

            setSelectedItem(null);
            setDisableAddButton(false);
            setQty(0);
            setDiscount1("0");
            setDiscount2("0");

            addProductFormRef.current.searchSelectClearValue();
            addProductFormRef.current.searchSelectfocus();
        } catch (err: any) {
            setDisableAddButton(false);
        }
    };

    // GET ITEMS
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
            "filter[customer_code]": offer?.data?.customer?.code
        };

        const res = await client.get("products", { params: params });

        // console.log(res);

        return res.data.data.map((item: any) => {
            let pieces = null;
            if (parseInt(item.piecesperpackage, 10) > 1) {
                pieces = <>({parseInt(item.piecesperpackage, 10)} {item.piecesperpackageuom})</>;
            }
            return {
                label: <span>{item.code} {item?.replacing_code && (item?.replacing_code === value) ? <span className='font-bold p-1 rounded'>{t("Vecchio codice")}: {item?.replacing_code}</span> : null} - {item.description} {pieces}</span>,
                value: item
            };
        });
    }, [offer?.data?.customer?.code]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    const manuallyUpdateCart = async () => {
        await updatePrices({ cart_id: offer?.data?.id });
        setOpenUpdatedProductsMessage(true);
        setOpenConfirmUpdatePrices(false);
    };

    // set initial focus on item or customer select based on user roles
    useEffect(() => {
        if (roles(user?.constants?.external_roles) || offer?.data.customer) {
            addProductFormRef?.current?.searchSelectfocus();
        } else {
            customerSelect?.current?.focus();
        }
    }, [roles, offer?.data.customer]);

    // set focus on qty when an item is selected
    useEffect(() => {
        addProductFormRef?.current?.qtyInputFocus();
        addProductFormRef?.current?.qtyInputSelect();
    }, [selectedItem]);

    useEffect(() => {
        setTitle(`Offerta #${offer?.data?.offer_number}${offer?.data?.offer_version !== "0" ? `/${offer?.data?.offer_version}` : ""}`);
    }, [offer]);

    useEffect(() => {
        if (priceChanges?.data?.length > 0) setOpenPriceChangesMessage(true);
    }, [priceChanges]);

    useEffect(() => {
        setIsOfferValid(isTableValid.length === 0 && !offer?.data?.items.find((e: any) => !!e?.is_minsell_invalid || !!e?.is_invalid));
    }, [offer, isTableValid]);

    return (
        (
            offer
                ? <>

                    {/* CONFIRM PRICE CHANGES MODAL */}
                    <Modal title={t("Aggiorna sconti")} open={OpenConfirmUpdatePrices} onClose={setOpenConfirmUpdatePrices}>
                        <div className="space-y-4">
                            <p>{t("ATTENZIONE: Gli sconti e i prezzi netti inseriti manualmente saranno aggiornati in base alle più recenti scontistiche personalizzate del cliente.")}</p>

                            <div className="text-right space-x-2 mt-4">
                                <Button variant={Button.variant.text} onClick={() => setOpenConfirmUpdatePrices(false)}>{t("Annulla")}</Button>
                                <Button variant={Button.variant.primary} onClick={manuallyUpdateCart}>{t("Procedi")}</Button>
                            </div>
                        </div>
                    </Modal>

                    {/* react toastfy */}
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                    />

                    {/* empty cart list modal */}
                    {
                        openEmptyList &&
                        <Modal title={t("Svuota carrello")} open={openEmptyList} onClose={setOpenEmptyList}>
                            <p>{t("Sicuro di voler svuotare il carrello? Questa operazione non può essere annullata.")}</p>
                            <div className="text-right space-x-2 mt-4">
                                <Button variant={Button.variant.text} onClick={() => setOpenEmptyList(false)}>{t("Annulla")}</Button>
                                <Button variant={Button.variant.primary} onClick={handleEmptyClick}>{t("Procedi")}</Button>
                            </div>
                        </Modal>
                    }

                    {/* loader */}
                    {(updatePricesLoading || isLoading || isFetching || ((isPriceChangesLoading || isPriceChangesFetching) && !!offer?.data?.is_dirty)) && <Loader />}

                    <form onSubmit={handleSubmit}>
                        {/* SELECT CUSTOMER */}
                        <CustomerForm
                            ref={customerSelect}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            readonly={offer?.data?.customer?.type !== "DUMMY CUSTOMER"}
                            data={offer}
                            handleCustomerChange={handleCustomerChange}
                            loadCustomersOptions={loadCustomersOptions}
                        />

                        {/* ADD PRODUCT */}
                        {
                            offer?.data?.customer?.alloworder &&
                            <AddProductForm
                                data={offer}
                                ref={addProductFormRef}
                                setSelectedItem={setSelectedItem}
                                selectedCustomer={selectedCustomer}
                                selectedItem={selectedItem}
                                disableAddButton={disableAddButton}
                                loadItemsOptions={loadItemsOptions}
                                handleItemSearchChange={handleItemSearchChange}
                                handleItemSearchInputChange={handleItemSearchInputChange}
                                openItem={openItem}
                                qty={qty}
                                setQty={setQty}
                                discount1={discount1}
                                setDiscount1={setDiscount1}
                                discount2={discount2}
                                setDiscount2={setDiscount2}
                            />
                        }
                    </form>
                    {/* PRICE CHANGES MESSAGE */}
                    {
                        priceChanges?.data?.length > 0 &&
                        <Message variant={Message.variant.warning} open={openPriceChangesMessage} handleCloseMessage={() => setOpenPriceChangesMessage(false)}>
                            <div className="space-y-4">
                                <div>
                                    <p>{t("ATTENZIONE: I  prezzi dei seguenti articoli sono aggiornati al nostro listino più recente:")}</p>
                                    <p>{t("Verifica l'importo totale prima di procedere allo step successivo.")}</p>
                                </div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th className="pr-4 pb-2 text-left">{t("Codice articolo")}</th>
                                            <th className="pr-4 pb-2 text-left">{t("Descrizione")}</th>
                                            <th className="pr-4 pb-2 first-letter:uppercase">{t("prezzo vecchio")}</th>
                                            <th className="pr-4 pb-2 first-letter:uppercase">{t("prezzo nuovo")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priceChanges?.data?.map((priceChange: any, index: number) => (
                                            <tr key={index}>
                                                <td className="pr-4 pb-2">{priceChange?.code}</td>
                                                <td className="pr-4 pb-2">{priceChange?.description}</td>
                                                <td className="pr-4 pb-2 text-right">{priceChange?.old_price}</td>
                                                <td className="pr-4 pb-2 text-right">{priceChange?.new_price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Message>
                    }

                    {/* DISCOUNT CHANGES MESSAGE */}
                    <Message variant={Message.variant.warning} open={openUpdatedProductsMessage} handleCloseMessage={() => setOpenUpdatedProductsMessage(false)}>
                        {
                            updatedPrices?.data?.data?.length > 0 ?
                                <div className="space-y-4">
                                    <div>
                                        <p>{t("ATTENZIONE: I  prezzi dei seguenti articoli sono aggiornati al nostro listino più recente:")}</p>
                                        <p>{t("Verifica l'importo totale prima di procedere allo step successivo.")}</p>
                                    </div>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="pr-4 pb-2 text-left">{t("Codice articolo")}</th>
                                                <th className="pr-4 pb-2 text-left">{t("Descrizione")}</th>
                                                <th className="pr-4 pb-2 first-letter:uppercase">{t("sconto vecchio %")}</th>
                                                <th className="pr-4 pb-2 first-letter:uppercase">{t("sconto nuovo %")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                updatedPrices?.data?.data?.map((priceChange: any, index: number) => (
                                                    <tr key={index}>
                                                        <td className="pr-4 pb-2">{priceChange?.code ? priceChange?.code : "-"}</td>
                                                        <td className="pr-4 pb-2">{priceChange?.description}</td>
                                                        <td className="pr-4 pb-2 text-right space-y-2">
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                                                                <div>{priceChange?.old_discount_1 ? priceChange?.old_discount_1 + "%" : "-"}</div>
                                                            </div>
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                                                                <div>{priceChange?.old_discount_2 ? priceChange?.old_discount_2 + "%" : "-"}</div>
                                                            </div>
                                                        </td>
                                                        <td className="pr-4 pb-2 text-right space-y-2">
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                                                                <div>{priceChange?.new_discount_1 ? priceChange?.new_discount_1 + "%" : "-"}</div>
                                                            </div>
                                                            <div className="leading-8 flex gap-2 items-center">
                                                                <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                                                                <div>{priceChange?.new_discount_2 ? priceChange?.new_discount_2 + "%" : "-"}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div>
                                    {t("Nessuno sconto e/o prezzo netto manuale da aggiornare.")}
                                </div>
                        }
                    </Message>

                    {/* MARGIN CALCULATOR TOGGLE */}
                    {
                        can(["can_see_margin_calculator"]) &&
                        <div className="flex justify-end pb-2">
                            <Switch checked={showMarginColumns} setChecked={setShowMarginColumns} label={t("Visualizza margin calculator")} />
                        </div>
                    }

                    {/* OFFERS TABLE */}
                    {
                        offer?.data?.items?.length > 0 &&
                        <>
                            {/* ACTIONS */}
                            {/* {
                                offer?.data?.items.length >= 10 &&
                                <div className='flex justify-between mb-3'>
                                    <div>
                                        <Button icon={Button.icon.delete} variant={Button.variant.text} onClick={() => setOpenEmptyList(true)}>
                                            {t("Svuota lista prodotti")}
                                        </Button>

                                    </div>
                                    <div className='space-x-4 mb-3'>
                                        {
                                            !roles(user?.constants?.external_roles) &&
                                            <Button
                                                variant={Button.variant.secondary}
                                                onClick={() => setOpenConfirmUpdatePrices(true)}
                                                isDisabled={!isOfferValid}
                                            >
                                                {t("Ripristina sconti")}
                                            </Button>
                                        }
                                        <Button
                                            variant={Button.variant.primary}
                                            onClick={handleNextStepClick}
                                            isDisabled={!isOfferValid}
                                        >
                                            {t("Procedi")}
                                        </Button>
                                    </div>
                                </div>
                            } */}

                            {/* OFFER TABLE */}
                            <OfferTable
                                cart={offer}
                                setIsTableValid={setIsTableValid}
                                isTableValid={isTableValid}
                                offerColumns={columns}
                                showMarginColumns={showMarginColumns}
                            />

                            {/* ACTIONS */}
                            <div className='flex justify-between'>
                                <div>
                                    <Button icon={Button.icon.delete} variant={Button.variant.text} onClick={() => setOpenEmptyList(true)}>
                                        {t("Svuota lista prodotti")}
                                    </Button>
                                </div>
                                <div className='space-x-4'>
                                    {
                                        !roles(user?.constants?.external_roles) &&
                                        <Button
                                            variant={Button.variant.secondary}
                                            onClick={() => setOpenConfirmUpdatePrices(true)}
                                            isDisabled={!isOfferValid}
                                        >
                                            {t("Ripristina sconti")}
                                        </Button>
                                    }
                                    <Button
                                        variant={Button.variant.primary}
                                        onClick={handleNextStepClick}
                                        isDisabled={!isOfferValid || !offer.data.customer.alloworder}
                                    >
                                        {t("Procedi")}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </>
                : null
        )
    );
};
