import { Title } from "../components/Title";
import { useTranslation } from "react-i18next";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import useGetCustomers from "../api/customers/useGetCustomers";
import { Route, Routes } from "react-router-dom";
import AdministrativeInfo from "./AdministrativeInfo";
import BillingDetails from "./BillingDetails";
import LogisticsDetails from "./LogisticsDetails";
import { Tab, Tabs } from "../components/Tabs";
import useProfile from "../api/profile/useProfile";
import { useState } from "react";
import Contacts from "./Contacts";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import Financial from "./Financial";
import { Slide, ToastContainer } from "react-toastify";
import RequireAuth from "../auth/RequireAuth";

const Profile = () => {
    const { t } = useTranslation();

    const { loadCustomersOptions } = useGetCustomers();
    const { user, roles, can } = useAuthContext();

    // state
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [isMasterfileSent, setIsMasterfileSent] = useState<boolean>(false);

    // react query
    const { data: profile } = useProfile(selectedCustomer?.code);

    const tabs: Tab[] = [
        {
            title: "Il mio profilo",
            to: "/profile",
            isVisibile: can(["can_see_profile_data"]),
        },
        {
            title: "Dati di fatturazione",
            to: "administrative-info",
            isVisibile: can(["can_see_administrative_data"]),
        },
        {
            title: "Dati di spedizione",
            to: "logistics",
            isVisibile: can(["can_see_logistic_data"]),
        },
        {
            title: "Contatti",
            to: "contacts",
            isVisibile: can(["can_see_contacts_data"]),
        },
        {
            title: "Dati finanziari",
            to: "financial",
            isVisibile: can(["can_see_financial_data"])
        },
    ];


    const handleCustomerChange = (e: any) => {
        setIsMasterfileSent(false);
        setSelectedCustomer(e);
    };

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title>{t("Anagrafica")}</Title>

            <fieldset>
                <legend className="sr-only">{t("Cliente")}</legend>

                <div className="w-full md:w-1/2">
                    <CustomerSelect
                        label="Seleziona cliente"
                        loadCustomersOptions={loadCustomersOptions}
                        handleChange={handleCustomerChange}
                        setSelectedCustomer={setSelectedCustomer}
                        selectedCustomer={selectedCustomer}
                    />
                </div>
            </fieldset>

            {selectedCustomer &&
                <>
                    <Tabs tabs={tabs} className="mt-10" />

                    <Routes>
                        {/* PROFILE */}
                        <Route element={<RequireAuth allowedRoles={["can_see_profile_data"]} />}>
                            <Route
                                path="/"
                                element={<BillingDetails selectedCustomer={selectedCustomer} isMasterfileSent={isMasterfileSent} setIsMasterfileSent={setIsMasterfileSent} data={profile} />}
                            />
                        </Route>


                        {/* ADMINISTRATIVE INFO */}
                        <Route element={<RequireAuth allowedRoles={["can_see_administrative_data"]} />}>
                            <Route
                                path="administrative-info"
                                element={<AdministrativeInfo data={profile} />}
                            />
                        </Route>


                        {/* LOGISTICS */}
                        <Route element={<RequireAuth allowedRoles={["can_see_logistic_data"]} />}>
                            <Route
                                path="logistics"
                                element={<LogisticsDetails data={profile} />}
                            />
                        </Route>


                        {/* CONTACTS */}
                        <Route element={<RequireAuth allowedRoles={["can_see_contacts_data"]} />}>
                            <Route
                                path="contacts"
                                element={<Contacts data={profile} />}
                            />
                        </Route>


                        {/* FINANCIAL */}
                        <Route element={<RequireAuth allowedRoles={["can_see_financial_data"]} />}>
                            <Route path="financial" element={<Financial selectedCustomer={selectedCustomer} />} />
                        </Route>
                    </Routes>


                    {/* react toastfy */}
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                    />

                    {/* AVATAR GROUP BANNER */}
                    {
                        roles(user?.constants?.external_roles) &&
                        <div className="flex p-10 justify-center bg-secondary bg-opacity-5 w-full mt-12">
                            <div className="flex max-lg:flex-col items-center gap-24">
                                <div className="flex gap-4 max-lg:flex-col max-lg:items-center ">
                                    <img src={`https://resources.rothoblaas.com/eservice-images/people/${selectedCustomer?.technicalSale?.email?.split("@")[0]?.toLowerCase().replaceAll("xx$", "")}`} className="w-28 h-28 object-cover rounded-full" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "/profle_not_found.webp";
                                    }} alt="Technical sales photo" />

                                    <div className="flex flex-col gap-2 justify-center text-base max-lg:text-center">
                                        <span className="font-semibold">{selectedCustomer?.technicalSale?.name}<span className="text-gray-300 text-sm"> - {t("TECNICO COMMERCIALE")}</span></span>
                                        <span className="font-light">{selectedCustomer?.technicalSale?.phone}</span>
                                        <a href={`mailto:${selectedCustomer?.technicalSale?.email}`} className="font-semibold text-secondary underline">{selectedCustomer?.technicalSale?.email}</a>
                                    </div>
                                </div>
                                <div className="flex gap-4 max-lg:flex-col max-lg:items-center ">
                                    <img src={`https://resources.rothoblaas.com/eservice-images/people/${selectedCustomer?.customerService?.email?.split("@")[0]?.toLowerCase().replaceAll("xx$", "")}`} className="w-28 h-28 object-cover rounded-full" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "/profle_not_found.webp";
                                    }} alt="Technical sales photo" />

                                    <div className="flex flex-col gap-2 justify-center text-base max-lg:text-center">
                                        <span className="font-semibold">{selectedCustomer?.customerService?.name}<span className="text-gray-300 text-sm"> - {t("CUSTOMER SERVICE")}</span></span>
                                        <span className="font-light">{selectedCustomer?.customerService?.phone}</span>
                                        <a href={`mailto:${selectedCustomer?.customerService?.email}`} className="font-semibold text-secondary underline">{selectedCustomer?.customerService?.email}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default Profile;
