import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { ChangeCell } from "./UnavailableItemsTable";
import { FaTrash } from "react-icons/fa";
import { useCartItemsMutations } from "../../../../api/carts/useCartItemsMutations";

type Props = {
    availableItems: any,
    unavailableItems: any,
    expeditionDays: any,
    cart: any;
    coverDate: string | null;
};

export const DeliveryItemsTable = ({ availableItems, unavailableItems, expeditionDays, cart, coverDate }: Props) => {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();
    const { shipping_method } = useShippingContext();

    // react query
    const { deleteProduct, updateProduct } = useCartItemsMutations();

    const handleRemoveItem = useCallback(async (item: any) => {
        const params = {
            cart_id: cart?.data?.id,
            product_id: item.id,
            body: roles(user?.constants?.external_roles) ? { quantity: item.rowQty - item.qty } : { quantity: item.rowQty - item.qty, discount_1: item.discount_1, discount_2: item.discount_2 }
        };

        // olny splitted items have rowQty property
        if (item.rowQty) {
            if (item.rowQty === item.qty) {
                await deleteProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: item.id });
            } else {
                await updateProduct.mutateAsync(params);
            }
        }
    }, [cart?.data?.id]);


    const data = useMemo(
        () => [
            ...availableItems.map((item: any) => ({ ...item, status: "available" })),
            ...unavailableItems.map((item: any) => ({ ...item, status: "unavailable" })),
        ],
        [availableItems, unavailableItems]
    );

    const columns: any = useMemo(
        () => [
            {
                accessor: "code",
                width: 100,
                minWidth: 100,
                Cell: (props: any) => (
                    <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                )
            },
            {
                accessor: "description",
                width: 250,
                minWidth: 250,
                Cell: (props: any) => (
                    <>
                        <div className='font-bold mb-1'>{props.cell.row.original.code.toUpperCase()}</div>
                        <span>{props.cell.row.original.description}</span>
                    </>
                )
            },
            {
                accessor: "qty",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const numberFormat = new Intl.NumberFormat(user?.language_id);
                    return <div className="flex flex-col gap-1">
                        <span>{t("Quantità")}</span>
                        <span>{numberFormat.format(qty) + " " + t("Pezzi")}</span>
                    </div>;
                }
            },
            {
                id: "disponibilità",
                Cell: (props: any) => {
                    if (props?.cell?.row?.original?.status === "unavailable") {

                        const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

                        const availableQuantity = props.row.original.availabilityInfo?.availableQuantity;
                        const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;

                        const isNotAvailable = props.row.original.rowQty > availableQuantity;

                        return (
                            <div className='flex'>
                                {props.row.original.material_type === 5 && isNotAvailable ? (
                                    roles(user?.constants?.external_roles) ? (
                                        <div className='text-rose-500'>{t("contattare TS per la disponibilità")}</div>
                                    ) : (
                                        <div className='text-rose-500'>{t("contattare CS per la disponibilità")}</div>
                                    )
                                ) : (
                                    <>

                                        {
                                            availabilityCoverDate ?
                                                dateFormat.format(new Date(availabilityCoverDate))
                                                :
                                                <span>{t("In approvvigionamento")}</span>
                                        }
                                    </>
                                )}
                            </div>
                        );
                    }
                    return "";
                }

            },
            {
                id: "sostituisci",
                Cell: (props: any) => {
                    if (props?.cell?.row?.original?.status === "unavailable") {
                        return <ChangeCell cart={cart} row={props?.cell?.row} />;
                    }
                    return "";
                }
            },
            {
                id: "elimina",
                Cell: (props: any) => {
                    const item = props.row.original;
                    if (props?.cell?.row?.original?.status === "unavailable") {

                        return (
                            <>
                                <FaTrash
                                    className="cursor-pointer hover:text-secondary"
                                    onClick={(() => handleRemoveItem(item))}
                                />
                            </>
                        );
                    }
                    return "";
                }
            },
        ],
        [t, user?.language_id]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, defaultColumn, initialState: { pageSize: 100 }, cart }, useSortBy, usePagination);

    return (
        <div>
            <div className="flex items-center gap-x-2 px-6 py-3 border bg-neutral-weaker font-medium mt-8">
                {
                    unavailableItems?.length <= 0 ? <span className="text-lime-500">{t("Tempi di consegna da {{min_days}} a {{max_days}} giorni", {
                        min_days: expeditionDays[shipping_method?.name]?.min_days ?? -1,
                        max_days: expeditionDays[shipping_method?.name]?.max_days ?? -1,
                    })}</span> : coverDate ? <span>{t("Arrivo stimato in magazzino")}: {coverDate}</span> : <span>{t("In approvvigionamento")}</span>
                }
            </div>
            <div className='overflow-y-scroll overflow-visible min-h-min max-h-[304px] border-l border-r border-b'>
                <table {...getTableProps()} className="w-full text-xs font-light">
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className={`border-b last:border-b-0 ${row?.original?.status === "unavailable" ? "bg-red-50" : ""}`}>
                                    {row.cells.map((cell: any) => (
                                        <td {...cell.getCellProps({ className: (cell.column as any).className, style: { minWidth: cell.column.minWidth, width: cell.column.width } })} className="p-3">
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
