import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { Title } from "../components/Title";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useProfileMutations from "../api/profile/useProfileMutations";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import useMastefileMutation from "../api/profile/useMasterfileMutations";
import { toast } from "react-toastify";

type BillingDetailsProps = {
    setIsMasterfileSent: any,
    isMasterfileSent: any,
    selectedCustomer: any,
    data: any
}

export default function BillingDetails({ data: profile, selectedCustomer, isMasterfileSent, setIsMasterfileSent }: BillingDetailsProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { can } = useAuthContext();

    // state
    const [isEditing, setIsEditing] = useState<boolean>(false);

    // react form hook
    const { register, handleSubmit, reset, formState: { isDirty, dirtyFields } } = useForm({ shouldUnregister: true });
    const { createMasterfile } = useMastefileMutation();

    // react query
    const { updateProfile } = useProfileMutations();

    const onSubmit = async (data: any) => {
        if (isDirty) {
            const mappedData: any = {};
            Object.keys(dirtyFields).map((field: any) => {
                if (!mappedData[field]) {
                    mappedData[field] = {};
                }

                mappedData[field] = data[field];
            });

            await updateProfile.mutateAsync({ customer_code: profile?.data?.code, billingDetails: mappedData });
            setIsEditing(false);
            navigate("/profile-sent");
        }
    };

    const handleSendMasterfile = () => {
        createMasterfile.mutate({ customer_code: selectedCustomer?.code }, {
            onSuccess: () => {
                setIsMasterfileSent(true);
            },
            onError: () => {
                toast.error(t("Errore durante l'invio"));
                setIsMasterfileSent(false);
            }
        });
    };

    useEffect(() => {
        if (profile) {
            reset({
                customer_code: profile?.data?.code,
                customer_name: profile?.data?.name,
                address: profile?.data?.invoicing?.street,
                region: profile?.data?.invoicing?.region?.description?.description,
                postalcode: profile?.data?.invoicing?.postalcode,
                city: profile?.data?.invoicing?.city,
                country: profile?.data?.invoicing?.country_id,
            });
        }
    }, [profile, reset]);

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            <form className="px-6" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <Title className="mb-6" variant={Title.variant.secondary}>{t("I miei dati")}</Title>
                    <div className="grid grid-cols-2 gap-6">
                        <div className={"font-light col-span-2"}>
                            <label htmlFor="customer_code">{t("Codice cliente")}</label>
                            <input {...register("customer_code")} type='text' className="grid-span-1" id="customer_code" disabled />
                        </div>
                        <div className={"font-light col-span-2"}>
                            <label htmlFor="customer_name">{t("Ragione sociale")}</label>
                            <input {...register("customer_name")} type='text' className="grid-span-1" id="customer_name" disabled={!isEditing} />
                        </div>
                    </div>

                    <Title className="mt-10 mb-6" variant={Title.variant.secondary}>{t("Indirizzo di fatturazione")}</Title>
                    <div className="grid grid-cols-2 gap-6">
                        <div className="font-light">
                            <label htmlFor='address'>{t("Indirizzo")}</label>
                            <input {...register("address")} type="text" className="grid-span-1" id="address" disabled={!isEditing} />
                        </div>
                        <div className="font-light">
                            <label htmlFor='region'>{t("Provincia")}</label>
                            <input {...register("region")} type="text" className="grid-span-1 uppercase" id="region" disabled={!isEditing} />
                        </div>
                        <div className="font-light">
                            <label htmlFor='postalcode'>{t("CAP")}</label>
                            <input {...register("postalcode")} type="text" className="grid-span-1" id="postalcode" disabled={!isEditing} />
                        </div>
                        <div className="font-light">
                            <label htmlFor='city'>{t("Città")}</label>
                            <input {...register("city")} type="text" className="grid-span-1" id="city" disabled={!isEditing} />
                        </div>
                        <div className="font-light">
                            <label htmlFor='country'>{t("Paese")}</label>
                            <input {...register("country")} type="text" className="grid-span-1" id="country" disabled={!isEditing} />
                        </div>
                    </div>

                    <div className="flex w-full justify-end mt-6">
                        {
                            isEditing &&
                            <div className="space-x-4">
                                {/* <Button
                                className="mt-6"
                                onClick={() => setIsEditing(false)}
                                type="button"
                                variant={Button.variant.text}
                            >
                                {t("Annulla")}
                            </Button> */}
                                <Button className="mt-6" isDisabled={!isDirty}>
                                    {t("Invia richiesta modifica")}
                                </Button>
                            </div>
                        }

                        <div className="flex gap-4 items-start">
                            {
                                can(["can_generate_masterfile"]) &&
                                <div className="w-fit text-green-500 flex flex-col gap-3">
                                    <Button onClick={handleSendMasterfile} isDisabled={isMasterfileSent}>{t("Invia masterfile")}</Button>
                                    <span className="">{isMasterfileSent && t("Masterfile inviato con successo")}</span>
                                </div>
                            }
                            {
                                !isEditing &&
                                <Button
                                    onClick={() => setIsEditing(true)}
                                    type="button"
                                >
                                    {t("Modifica")}
                                </Button>
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
