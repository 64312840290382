import { useTranslation } from "react-i18next";
import { useSplitAvailability } from "../../../../hooks/useSplitAvailability";
import AvailableItemsTable from "./AvailableItemsTable";
import UnavailableItemsTable from "./UnavailableItemsTable";
import { Section } from "../../../Section";
import { DeliveryItemsTable } from "./DeliveryItemsTable";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";

type Props = {
    cart: any,
    expeditionDays: any
};

export default function DeliveryAvailability({ cart, expeditionDays }: Props) {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const { availableItems, unavailableItems } = useSplitAvailability(cart?.data?.items);
    const { delivery_restriction, setDeliveryRestriction } = useShippingContext();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // state
    const [coverDate, setCoverDate] = useState<any>();

    const partial_delivery = 1;
    const complete_delivery = 2;
    const partialDeliveryDisabled = unavailableItems?.length <= 0 ;

    const handleOptionChange = (event: any) => {
        setDeliveryRestriction(event?.target?.value);
    };

    useEffect(() => {
        if (partialDeliveryDisabled) {
            setDeliveryRestriction(complete_delivery);
        }
    }, [unavailableItems, delivery_restriction, cart]);

    useEffect(() => {
        if (unavailableItems.length > 0) {
            // find if a product has coverDate set to null
            const productWithoutCoverDate = unavailableItems?.find((item: any) => !item?.availabilityInfo?.availabilityCoverDate);
            if (productWithoutCoverDate) {
                setCoverDate(null);
            } else {
                const maxDate = new Date(Math.max(...unavailableItems.map((item: any) => new Date(item?.availabilityInfo?.availabilityCoverDate))));
                setCoverDate(dateFormat.format(maxDate));
            }
        }
    }, [unavailableItems]);

    return (
        <Section title={"Consegna ordine"} className="mb-8">
            <div className="flex flex-col gap-3">
                <div className="py-2 px-6 border-b">
                    <label className="flex gap-3 items-center cursor-pointer">
                        <input type="radio" id="partial_delivery" name="delivery" value={1} className="accent-black" checked={delivery_restriction === partial_delivery} disabled={partialDeliveryDisabled} onChange={handleOptionChange} />
                        <p className={partialDeliveryDisabled ? "text-gray-300" : ""}>
                            <span className="font-semibold">{t("Consegna parziale")}: </span>
                            {t("I prodotti verranno evasi in base alla disponibilità dello stock")}
                        </p>
                    </label>
                </div>
                {/* <hr className="w-full" /> */}
                <div className="py-2 px-6 border-b">
                    <label className="flex gap-3 items-center cursor-pointer">
                        <input type="radio" id="complete_delivery" name="delivery" value={2} className="accent-black" checked={delivery_restriction === complete_delivery} onChange={handleOptionChange} />
                        <p>
                            <span className="font-semibold">{t("Consegna completa")}: </span>
                            {t("I prodotti verranno evasi quando tutti presenti in stock")}
                        </p>
                    </label>
                </div>
                {
                    delivery_restriction === partial_delivery ?
                        <div>
                            {
                                availableItems.length > 0 ?
                                    <div className="mt-8">
                                        <AvailableItemsTable
                                            expeditionDays={expeditionDays}
                                            items={availableItems}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                unavailableItems.length > 0 ?
                                    <div className="mt-8">
                                        <UnavailableItemsTable items={unavailableItems} cart={cart} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        :
                        <DeliveryItemsTable cart={cart} availableItems={availableItems} unavailableItems={unavailableItems} expeditionDays={expeditionDays} coverDate={coverDate} />
                }
            </div>
        </Section>
    );
}
